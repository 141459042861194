import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAuth } from "../../hooks/useAuth";
import InvestmentPeriodSlider from "./InvestmentPeriodSlider";
import InvestmentPriceSlider from "./InvestmentPriceSlider";
import InvestmentPreferencesChips from "./InvestmentPreferencesChips";
import AccreditedInvestorDeclaration from "./Questionnaire";

const BuyerSurvey = ({ onSave }) => {
  const { user } = useAuth();

  const [errorMessage, setErrorMessage] = useState('');
  const [investmentPreferences, setInvestmentPreferences] = useState('');
  const [investmentPrice, setInvestmentPrice] = useState(100000);
  const [investmentPeriod, setInvestmentPeriod] = useState(2);
  const [isNextClicked, setIsNextClicked] = useState(false);

  const handleNextClick = (event) => {
    event.preventDefault();
    if (
      !investmentPreferences ||
      !investmentPrice ||
      !investmentPeriod
    ) {
      setErrorMessage("Please fill in all required fields.");
    } else {
      setErrorMessage("");
      setIsNextClicked(true);
    }
  };

  return (
    <Box>
      {!isNextClicked && (
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Buyer Preferences
          </Typography>
          <div className="separatorEmptyLarge"></div>
          {errorMessage && (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
          <Box
            component="form"
            onSubmit={handleNextClick}
            noValidate
            sx={{ mt: 1 }}
          >
            <InvestmentPreferencesChips
              selectedPreferences={investmentPreferences}
              onSelectPreference={setInvestmentPreferences}
              setErrorMessage={setErrorMessage}
            />

            <div className="separatorEmptyLarge"></div>
            <div className="separatorEmptyMedium"></div>

            <InvestmentPriceSlider
              value={investmentPrice}
              onChange={(e, newValue) => setInvestmentPrice(newValue)}
            />

            <div className="separatorEmptyLarge"></div>
            <div className="separatorEmptyMedium"></div>

            <Box sx={{ width: 300, margin: "auto", textAlign: "center" }}>
              <InvestmentPeriodSlider
                value={investmentPeriod}
                onChange={(e, newValue) => setInvestmentPeriod(newValue)}
              />
            </Box>

            <div className="separatorEmptyLarge"></div>

            {/* <FormControlLabel
          control={
            <Checkbox
              size="small"
              name="accreditedInvestor"
              checked={accreditedInvestor}
              onChange={(e) => setAccreditedInvestor(e.target.checked)}
            />
          }
          label={<Typography variant="body2">Accredited Investor</Typography>}
        /> */}

            {/* <div className='separatorEmptyMedium'></div>
        <div>
          <strong>Not certain?</strong><br />
          Provide your best estimate and reach out to a representative on the following page for personalized assistance.
        </div>
        <div className='separatorEmptySmall'></div> */}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: "none" }}
              style={{ borderRadius: 20 }}
              color="orange"
            >
              Next
            </Button>
          </Box>
        </Box>
      )}
      {isNextClicked && (
        <Box sx={{display:'grid',placeItems:'center'}}>
        <AccreditedInvestorDeclaration
          buyerSurveryData={{
            user,
            investmentPreferences,
            investmentPrice,
            investmentPeriod,
            onSave,
          }}
        />
        </Box>
      )}
    </Box>
  );
};

export default BuyerSurvey;