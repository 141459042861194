import React from 'react';

function ViewIcon() {

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_12_1931)">
      <path d="M19.498 12.001V16.501C19.498 17.164 19.2346 17.7999 18.7658 18.2687C18.2969 18.7376 17.661 19.001 16.998 19.001H7.99799C7.33494 19.001 6.69906 18.7376 6.23022 18.2687C5.76138 17.7999 5.49799 17.164 5.49799 16.501V7.50098C5.49799 6.83794 5.76138 6.20205 6.23022 5.73321C6.69906 5.26437 7.33494 5.00098 7.99799 5.00098H12.498C12.6306 5.00098 12.7578 5.05365 12.8515 5.14742C12.9453 5.24119 12.998 5.36837 12.998 5.50098C12.998 5.63358 12.9453 5.76076 12.8515 5.85453C12.7578 5.9483 12.6306 6.00098 12.498 6.00098H7.99799C7.60016 6.00098 7.21863 6.15901 6.93732 6.44032C6.65602 6.72162 6.49799 7.10315 6.49799 7.50098V16.501C6.49799 16.8988 6.65602 17.2803 6.93732 17.5616C7.21863 17.8429 7.60016 18.001 7.99799 18.001H16.998C17.3958 18.001 17.7773 17.8429 18.0586 17.5616C18.34 17.2803 18.498 16.8988 18.498 16.501V12.001C18.498 11.8684 18.5507 11.7412 18.6444 11.6474C18.7382 11.5537 18.8654 11.501 18.998 11.501C19.1306 11.501 19.2578 11.5537 19.3515 11.6474C19.4453 11.7412 19.498 11.8684 19.498 12.001ZM15.498 6.00098H17.798L12.148 11.646C12.1014 11.6926 12.0644 11.7479 12.0392 11.8089C12.0139 11.8698 12.0009 11.935 12.0009 12.001C12.0009 12.0669 12.0139 12.1322 12.0392 12.1931C12.0644 12.254 12.1014 12.3094 12.148 12.356C12.1946 12.4026 12.2499 12.4396 12.3109 12.4648C12.3718 12.49 12.4371 12.503 12.503 12.503C12.5689 12.503 12.6342 12.49 12.6951 12.4648C12.756 12.4396 12.8114 12.4026 12.858 12.356L18.498 6.70998V9.00098C18.498 9.13358 18.5507 9.26076 18.6444 9.35453C18.7382 9.4483 18.8654 9.50098 18.998 9.50098C19.1306 9.50098 19.2578 9.4483 19.3515 9.35453C19.4453 9.26076 19.498 9.13358 19.498 9.00098V5.50098C19.498 5.36837 19.4453 5.24119 19.3515 5.14742C19.2578 5.05365 19.1306 5.00098 18.998 5.00098H15.498C15.3654 5.00098 15.2382 5.05365 15.1444 5.14742C15.0507 5.24119 14.998 5.36837 14.998 5.50098C14.998 5.63358 15.0507 5.76076 15.1444 5.85453C15.2382 5.9483 15.3654 6.00098 15.498 6.00098Z" fill="black"/>
      </g>
      <defs>
      <clipPath id="clip0_12_1931">
      <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
      </clipPath>
      </defs>
    </svg>
  );

}

export default ViewIcon;