import React, { useState, useContext } from "react";
import styles from "./DealCard.module.css";
import { ReactComponent as DealIcon } from "../../../../images/wallet/deal-card-icon.svg";
// import { ReactComponent as DownloadIcon } from '../../../../images/wallet/download.svg';
import { ReactComponent as EmailIcon } from "../../../../images/wallet/email.svg";
import { sendLeadToZapier, buildLeadData } from "../../../../utils/zapier";
import { useAuth } from "../../../../hooks/useAuth";
import { CompanyContext } from "../../../state/CompanyContext";

function DealCard(props) {
  // const { user } = useAuth();
  // const { state: { currentCompanyIdStocks } } = useContext(CompanyContext);

  const { title, url } = props;
  // const [zapierReceived, setZapierReceived] = useState(false);

  // const handleClick = async () => {
  //   const leadType = "docRequest";
  //   const leadContent = {
  //     userId: user.id,
  //     companyId: currentCompanyIdStocks,
  //     docTitle: title
  //   };
  //   const leadData = buildLeadData(leadType, leadContent);
  //   await sendLeadToZapier(leadData);
  //   setZapierReceived(true);
  // };

  return (
    <div className={styles.cardWrap}>
      <DealIcon alt="Deal Icon" />
      <div>
        <a
          className={styles.title}
          rel="noopener noreferrer"
          target="_blank"
          href={url}
        >
          {title}
        </a>
      </div>
      <div className={styles.subTitle}>Sent By Valoo</div>

      <div className="separatorEmptyLarge"></div>

      <div className={styles.container}>
        {/* <a href={url} className={styles.iconAndLinkContainer}>
          <DownloadIcon />
          <div className='separatorVerticalEmptySuperSmall'></div>
          <a className={styles.actionLink} href={url}>Download</a>
        </a>
        <div className={styles.separatorVerticalGray}></div> */}

        {/* {zapierReceived ? (
          <div className='fontReceived fontSmall'>Your request received.</div>
        ) : (
          <div onClick={handleClick} className={`pointer ${styles.iconAndLinkContainer}`}>
            <EmailIcon />
            <div className='separatorVerticalEmptySuperSmall'></div>
            <div className={styles.actionLink}>Send Via Email</div>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default DealCard;
