import React from 'react';
import CheersIcon from '../../../../../global/icons/CheersIcon';

function CompletionBuyCheers() {

  return (
    <>
      <div className='separatorEmptyLarge'></div>
      <div className='containerFlex alignItemsCenter'>
        <div>
          <CheersIcon />
        </div>
        <div className='separatorVerticalEmptyVerySmall'></div>
        <div className='fontLarger'>
          <strong>
            Congratulations, the investment transaction has been successfully completed!
          </strong>
        </div>
      </div>
    </>
  );
  
}

export default CompletionBuyCheers;