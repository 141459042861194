import React from "react";

function DoneIcon() {

  return (
    
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_108_3699)">
      <path d="M12 21.1069C16.9706 21.1069 21 17.0775 21 12.1069C21 7.13637 16.9706 3.10693 12 3.10693C7.02944 3.10693 3 7.13637 3 12.1069C3 17.0775 7.02944 21.1069 12 21.1069Z" fill="#3FCB70"/>
      <path d="M10.6964 15.5686L7.63863 12.5108C7.55049 12.4227 7.50098 12.3031 7.50098 12.1784C7.50098 12.0537 7.55049 11.9342 7.63863 11.846L8.30351 11.1811C8.39169 11.093 8.51126 11.0434 8.63594 11.0434C8.76062 11.0434 8.8802 11.093 8.96838 11.1811L11.0294 13.2398L15.4416 8.82759C15.5298 8.73945 15.6494 8.68994 15.7741 8.68994C15.8987 8.68994 16.0183 8.73945 16.1065 8.82759L16.7714 9.49247C16.8595 9.58065 16.909 9.70023 16.909 9.82491C16.909 9.94959 16.8595 10.0692 16.7714 10.1573L11.3601 15.5686C11.272 15.6564 11.1527 15.7057 11.0283 15.7057C10.9039 15.7057 10.7845 15.6564 10.6964 15.5686Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_108_3699">
      <rect width="24" height="24" fill="white" transform="translate(0 0.106934)"/>
      </clipPath>
      </defs>
    </svg>

  );

}

export default DoneIcon;