import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#011e37', // Specify your primary color
    },
    secondary: {
      main: '#ff7800', // Specify your secondary color
    },
    orange: {
      main: '#ff7800',
      contrastText: '#fff'
    },
    orangeText: {
      main: '#ff7800',
    },
    grey: {
      main: '#767b87',
    },
    white: {
      main: '#ffffff'
    },
    error: {
      main: '#f44336', // Specify your error color
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif', // Specify your desired font family
    fontSize: 14, // Specify the base font size
    h2: {
      fontSize: '35px',
      fontWeight: '600', 
      lineHeight: '38px'
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 8, // Custom border radius for all buttons
      },
      containedPrimary: {
        color: '#ffffff', // Custom text color for contained primary buttons
      },
    },
  },
});

export default theme;
