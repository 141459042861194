import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes, useNavigate } from "react-router-dom";
import LoginPage from "./components/login/LoginPage";
import Loader from "./components/UI/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import Dashboard from "./components/dashboard/Dashboard";
import { ProtectedLayout } from "./components/providers/AuthGuard";

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const [redirectLoading, setRedirectLoading] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function redirectAuth() {
      try {
        setRedirectLoading(true);
        await loginWithRedirect({
          appState: {
            returnTo: "/dashboard/home",
          },
        });
      } catch (error) {
      } finally {
        setRedirectLoading(false);
      }
    }
    if (!isAuthenticated && !isLoading) {
      redirectAuth();
    } else if (isAuthenticated && !isLoading) {
      navigate("/dashboard/home");
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading || redirectLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard | Valoo</title>
      </Helmet>
      <Routes>
        {/* <Route path="/" Component={LoginPage} /> */}
        <Route path="/" Component={Loader} />
        <Route path="/dashboard" element={<ProtectedLayout />}>
          <Route path="home" element={<Dashboard />} />
        </Route>
        <Route path="/callback" Component={Loader} />
      </Routes>

      {/* <Routes>
        
        <Route element={<PublicLayout />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
        </Route>

        <Route path="/dashboard" element={<ProtectedLayout />}>
          <Route path="home" element={<Dashboard />} />
        </Route>

      </Routes> */}
    </>
  );
}

export default App;
