import React from "react";
import NewsletterTitleImage from '../../../../images/titles/valoos-newsletters.svg'
import NewsletterList from "./NewsletterList"

export default function Newsletter() {

  return(
    <div className="maxWidth marginAuto">
      <img className="titleImage" src={NewsletterTitleImage} alt="Valoo's Newsletter" />
      <div>
        <NewsletterList />
      </div>
    </div>
  );

}