export function getStepsArray(isBuyMode) {
  if (isBuyMode) {
    return [
      {
        label: 'Offering'
      },
      {
        label: 'Signing',
      },
      {
        label: 'Transfer of Funds',
      },
      {
        label: 'Funds Confirmation',
      },
      {
        label: 'Joined The Partnership',
      },
      {
        label: 'Completion',
      },
    ];
  } else {
    return [
      {
        label: 'Offering'
      },
      {
        label: 'Approving',
      },
      {
        label: 'Signing',
      },
      {
        label: 'Pending',
      },
      {
        label: 'Transaction',
      },
      {
        label: 'Completion',
      },
    ];
  }
}