import React from "react";
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AccessibilityDialog from '../../../../dialogs/info/AccessibilityDialog';

export default function BottomLinks() {

  return (
    <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          typography: 'caption',
          '& > :not(style) ~ :not(style)': {
            ml: 2,
          },
        }}
      >
          <Link href="https://valoo.io/wp-content/uploads/2022/11/terms_of_use7.7.2020.pdf" target="_blank" rel="noopener noreferrer">Terms of use</Link>
          <Link href="https://valoo.io/wp-content/uploads/2023/03/%D7%9E%D7%93%D7%99%D7%A0%D7%99%D7%95%D7%AA-%D7%A4%D7%A8%D7%98%D7%99%D7%95%D7%AA-23.7.2020.pdf" target="_blank" rel="noopener noreferrer">Privacy policy</Link>
          <AccessibilityDialog />

      </Box>
  );

}