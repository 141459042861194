import React from 'react';
import styles from './Header.module.css'

function Header(props) {

  const { header } = props;
  
  if (!header || !header.items || !Array.isArray(header.items)) {
    return null; // Handle the case where the header prop is missing or has an incorrect structure
  }

  const { items } = header;
  const filteredItems = items?.filter(({ title }) => title !== '');

  return (
    <>
      <div className={styles.itemsContainer}>
        {filteredItems?.map((item, index) => (
          <div key={index}>
            <div><strong>{item?.title}</strong></div>
            <div>{item?.content}</div>
            <div className='separatorEmptyLarge'></div>
          </div>
        ))}
      </div>
    </>
  );

}

export default Header;
