import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import UsersTypeEnum from "../../constants/UsersTypeEnum";
import { useAuth } from "../../hooks/useAuth";
import Button from "@mui/material/Button";
import { sendLeadToZapier, buildLeadData } from "../../utils/zapier";

export default function SettingsDialog({ open, onClose }) {
  const { user } = useAuth();
  const [userType, setUserType] = useState(user.userType);
  const [zapierReceived, setZapierReceived] = useState(false);

  const handleClose = async () => {
    setZapierReceived(false);
    onClose();
  };

  const handleZapierClick = async () => {
    const leadType = "settingsChangeUserTypeRequest";
    const leadContent = {
      userId: user.id,
      userType: userType,
      phone: user?.phone || "",
    };
    const leadData = buildLeadData(leadType, leadContent);
    await sendLeadToZapier(leadData);
    setZapierReceived(true);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth="lg">
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value="Profile" aria-label="Tabs">
              <Tab
                label="Profile"
                value="Profile"
                sx={{ textTransform: "none" }}
              />
            </Tabs>
          </Box>
        </Box>

        <div className="separatorEmptyMedium"></div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: 4,
            paddingRight: 4,
          }}
        >
          <p>
            To request a change in your user type, please select your new user
            type from the options below.
          </p>
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="userType-label">
              Are you a buyer or a seller?
            </InputLabel>
            <Select
              labelId="userType-label"
              id="userType"
              name="userType"
              label="Are you a buyer or a seller?"
              defaultValue={user.userType}
              onChange={(event) => setUserType(event.target.value)}
            >
              <MenuItem value={UsersTypeEnum.Buyer}>Buyer</MenuItem>
              <MenuItem value={UsersTypeEnum.Seller}>Seller</MenuItem>
              <MenuItem value={UsersTypeEnum.BuyerAndSeller}>
                Buyer & Seller
              </MenuItem>
              <MenuItem value={UsersTypeEnum.Company}>Company</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <div className="separatorEmptyMedium"></div>
      </DialogContent>
      <DialogActions>
        {zapierReceived ? (
          <>
            <div className="fontReceived fontMedium">
              Your request received.
            </div>
            <div className="separatorVerticalEmptyVerySmall"></div>
            <Button
              onClick={handleClose}
              sx={{ color: (theme) => theme.palette.grey[500] }}
            >
              Close
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={handleClose}
              sx={{ color: (theme) => theme.palette.grey[500] }}
            >
              Cancel
            </Button>
            <Button color="orange" onClick={handleZapierClick}>
              Request Change
            </Button>
          </>
        )}
        <div className="separatorVerticalEmptyVerySmall"></div>
      </DialogActions>
    </Dialog>
  );
}
