import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import { Wallet } from "@mui/icons-material";
import { useAuth } from "../../../../hooks/useAuth";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import SettingsDialog from "../../../settings/SettingsDialog";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // == Is Mobile - START ==
  const isMobile = useIsMobile();
  // == Is Mobile - END ==

  // == Login - START ==
  const { user, logout } = useAuth();
  console.log("user => ", user);
  const handleCloseAndLogout = async () => {
    handleClose();
    await logout();
  };
  // == Login - END ==

  // == My Wallet - START ==
  function scrollToMyWallet() {
    var myWalletDiv = document.getElementById("myWallet");
    handleClose();
    setTimeout(() => {
      if (myWalletDiv) {
        myWalletDiv.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }
  // == My Wallet - END ==

  // == Settings - START ==
  const [isSettingsDialogOpen, setSettingsDialogOpen] = React.useState(false);

  function handleSettingsClick() {
    setSettingsDialogOpen(true);
    handleClose();
  }

  function handleSettingsDialogClose() {
    setSettingsDialogOpen(false);
  }
  // == Settings - END ==

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <Button onClick={handleClick} sx={{ minWidth: 80 }}>
            {/* <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              
            </IconButton> */}
            {isMobile ? (
              // Mobile version
              <></>
            ) : (
              // Desktop version
              <Typography
                variant="button"
                sx={{ fontWeight: 500, textTransform: "capitalize" }}
              >
                {user.fullname ?? user.fullName} &nbsp;&nbsp;&nbsp;
              </Typography>
            )}
            <Avatar sx={{ width: 32, height: 32 }} />
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={scrollToMyWallet}>
          <ListItemIcon>
            <Wallet fontSize="small" />
          </ListItemIcon>
          My Wallet
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleCloseAndLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

      <SettingsDialog
        open={isSettingsDialogOpen}
        onClose={handleSettingsDialogClose}
      />
    </React.Fragment>
  );
}
