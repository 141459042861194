import React from 'react';
import styles from './Footer.module.css'
import Bar from './bar/Bar';
import Terms from './terms/Terms';
import Version from './Version';

function Footer() {

  return(
    <footer className={`${styles.footer} primaryPadding`}>
      <Bar />
      <hr className={`${styles.hrLine} maxWidth`} />
      <Terms />
      <Version />
    </footer>
  );

}

export default Footer;
