import React from 'react';
import NounCelebrateIcon from '../../../../../global/icons/NounCelebrateIcon';

function TransactionSell() {

  return (
    <>
      <div>
        The sale is on the verge of finalization.
      </div>

      <div className='separatorEmptyLarge'></div>
      
      <div className='containerFlex alignItemsCenter'>
        <NounCelebrateIcon />
        <div className='separatorVerticalEmptySmall'></div>
        <strong>
          Congratulations! Your sale has been successfully concluded!
        </strong>
      </div>
    </>
  );

}

export default TransactionSell;