import React from 'react';
import styles from './ArticleItemMobile.module.css';
import { formatDateStringWithoutTime } from '../../utils/strings';
import PdfDialog from './PdfDialog';

const ArticleItemMobile = ({ article }) => {
  return (
    <div key={article.id} className={styles.card}>
      <div className={styles.cardImage}>
        <img src={article.featured_image} alt={article.title} />
      </div>
      <div className={styles.cardDetails}>
        <div className='fontSmall'>{article.companyname}{/* TODO: company name */}</div>
        <div className='fontBold text-ellipsis' title={article.title}>{article.title}</div>
        <div className='fontSmall fontGray'>{formatDateStringWithoutTime(article.date_modified)}</div>
        <div className='separatorEmptySmall'></div>
        <div className='containerFlex spaceBetween'>
          <div className='fontSmall marginVertical'>{article.author}</div>
          <div className='fontSmall orangeFont'>
            <PdfDialog companyId={article.company_id}  fileName={article.file_name}/>{/* 'Read More' button will be placed here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleItemMobile;