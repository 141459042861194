import React from 'react';
import LoadingDots from '../../../../../global/LoadingDots';
import HourGlassIcon from '../../../../../global/icons/HourGlassIcon';

function ApprovingSell() {

  return (
    <>
      <div className='separatorEmptyLarge'></div>
      <div className='separatorEmptyLarge'></div>
      
      <div className='containerFlex'>
        <strong>
          Waiting for the transaction to be approved by Valoo
        </strong>
        <div className='separatorVerticalEmptyVerySmall'></div>
        <LoadingDots />
      </div>

      <div className='separatorEmptyLarge'></div>
      <div className='separatorEmptyLarge'></div>


      <div className='containerFlex alignItemsCenter'>
        <HourGlassIcon />
        <div className='separatorVerticalEmptySmall'></div>
        <strong>
          We are checking the availability of the transaction, and we will send your transaction documents soon
        </strong>
      </div>
    </>
  );

}

export default ApprovingSell;