import React, { useRef, useState, useEffect, useContext } from 'react';
import styles from './Main.module.css';
import DealDocumentation from './DealDocumentation';
import Paragraphs from './Paragraphs';
import StayUpdatedArticles from '../../../stayupdated/StayUpdatedArticles';
import { CompanyContext } from '../../../state/CompanyContext';

function Main(props) {

  const {
    main: {
      images: { imageA, imageB, imageC, imageD },
      paragraphA,
      paragraphB
    },
  } = props;

  // == Company id - START ==
  const { state } = useContext(CompanyContext);
  const { currentCompanyIdWallet } = state;
  // == Company id - END ==


  //== width - START ==
  const separatorRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(null);

  useEffect(() => {
    // Update parentWidth when the component mounts
    setParentWidth(separatorRef.current.offsetWidth);

    // Update parentWidth when the window is resized
    const handleResize = () => {
      setParentWidth(separatorRef.current.offsetWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures this effect runs only once after initial render
  //== width - END ==

  return(
    <div className={styles.container}>
      
      <div className='separatorEmptyLarge'></div>


      <div className={styles.containerParagraphsAndImage}>
        <div>
          <Paragraphs paragraphA={paragraphA} paragraphB={paragraphB} />
        </div>
        <div className={styles.centerImage}>
          <img src={imageA} alt="A" className={styles.mainImage} onError={(e) => { e.target.style.display = 'none'; }}/>
        </div>
      </div>

      <div className='separatorEmptyLarge'></div>

      <div className={styles.containerThreeImages}>
        <div className={styles.imageBox}>
          <img src={imageB} alt="B" className={styles.threeImages} onError={(e) => { e.target.style.display = 'none'; }}/>
        </div>
        <div className={styles.imageBox}>
          <img src={imageC} alt="C" className={styles.threeImages} onError={(e) => { e.target.style.display = 'none'; }} />
        </div>
        <div className={styles.imageBox}>
          <img src={imageD} alt="D" className={styles.threeImages} onError={(e) => { e.target.style.display = 'none'; }}/>
        </div>
      </div>

      <div className='separatorEmptyLarge' ref={separatorRef}></div>
      <StayUpdatedArticles companyId={currentCompanyIdWallet} parentWidth={parentWidth} />
      <div className="separatorEmptyLarge"></div>
      <DealDocumentation />
      <div className='separatorEmptyLarge'></div>

    </div>
  );

}

export default Main;