import React, { useEffect, useState } from "react";
import PendingSellTax from "./PendingSellTax";
import { Slider } from "@mui/material";
import { apiInstance } from "../../../../../../services/api.service";
let sharesRaised =0
function PendingSell({ companyId }) {
  async function getShares(){
    try {
      const response = await apiInstance.get(`/api/user-wallet/user-wallet-shares-raised`);

      if (response.status >= 200 && response.status < 300) {
        const shares = response.data
        sharesRaised = shares?.sellPercentages
      }

    } catch (error) {
    }
  }
  useEffect(()=>{
    getShares()
  },[])
  const valueLabelFormat = (value) => `${value}%`;

  const sliderStyles = {
    '& .MuiSlider-thumb': {
        color: "#FF7800"
    },
    '& .MuiSlider-track': {
        color: "#FF7800"
    },
    '& .MuiSlider-rail': {
        color: "#acc4e4"
    },
    '& .MuiSlider-active': {
        color: "#FF7800"
    },
}

  return (
    <>
      <div>
        Great news! Your part is done, and now all that's left is a bit of
        patience. In the meantime, please feel free to upload the required
        documents, including the bank account management certificate and the
        Withholding tax certificate.
      </div>
      <div className="separatorEmptyLarge"></div>
      <div style={{ fontWeight: 600, fontSize: "16px" }}>Share Raised</div>
      <Slider
        aria-label="Default"
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        step={1}
        value={sharesRaised}

        sx={sliderStyles}
      />
      <div className="separatorEmptyLarge"></div>
      <PendingSellTax companyId={companyId}/>
      <div className="separatorEmptyLarge"></div>
      {/* <PendingSellBank companyId={companyId} /> */}
    </>
  );
}

export default PendingSell;