import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { sendLeadToZapier, buildLeadData } from "../../../utils/zapier";
import { useAuth } from "../../../hooks/useAuth";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function AddDialog({ open, onClose }) {
  // State to handle error message
  const [errorMessage, setErrorMessage] = React.useState("");

  const { user } = useAuth();

  const [companyName, setCompanyName] = React.useState("");
  const [companyWebsite, setCompanyWebsite] = React.useState("");
  const [companyDetails, setCompanyDetails] = React.useState("");

  const [thankYouMessage, setThankYouMessage] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (!form.checkValidity()) {
      // Mark the invalid fields with the "invalidInput" class
      const invalidElements = form.querySelectorAll(":invalid");
      invalidElements.forEach((element) => {
        element.classList.add("invalidInput");
      });

      setErrorMessage("Please fill in all required fields.");
      return; // Prevent form submission
    } else {
      // Remove the "invalidInput" class from all elements
      const allElements = form.elements;
      for (let i = 0; i < allElements.length; i++) {
        allElements[i].classList.remove("invalidInput");
      }
      handleZapierClick();
      setThankYouMessage(true);
    }
  };

  const handleZapierClick = async () => {
    const leadType = "addCompany";
    const leadContent = {
      userId: user.id,
      companyName: companyName,
      companyWebsite: companyWebsite,
      companyDetails: companyDetails,
      phone: user?.phone || "",
    };
    const leadData = buildLeadData(leadType, leadContent);
    await sendLeadToZapier(leadData);
  };

  const handleClose = async () => {
    setErrorMessage("");
    setThankYouMessage(false);
    onClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth="lg">
        <DialogTitle>Add A New Company</DialogTitle>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <DialogContent>
            {thankYouMessage ? (
              // Display thank you message
              <>
                <div className="fontReceived">
                  Thank you for your submission!
                </div>
                <div className="separatorEmptyLarge"></div>
                <DialogActions>
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogContentText>
                  Fill in the company details, and we will add it soon.
                </DialogContentText>
                <div className="separatorEmptySmall"></div>
                {errorMessage && (
                  <>
                    <Typography variant="body2" color="error">
                      {errorMessage}
                    </Typography>
                    <div className="separatorEmptySmall"></div>
                  </>
                )}
                <TextField
                  autoFocus
                  margin="dense"
                  id="companyName"
                  label="Company Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => setCompanyName(e.target.value)}
                  onBlur={(e) => e.target.classList.remove("invalidInput")}
                  required
                />
                <div className="separatorEmptySmall"></div>
                <TextField
                  margin="dense"
                  id="companyWebsite"
                  label="Website"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => setCompanyWebsite(e.target.value)}
                  onBlur={(e) => e.target.classList.remove("invalidInput")}
                  required
                />
                <div className="separatorEmptySmall"></div>
                <TextField
                  margin="dense"
                  id="companyDetails"
                  label="Details"
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  onChange={(e) => setCompanyDetails(e.target.value)}
                  onBlur={(e) => e.target.classList.remove("invalidInput")}
                  required
                />

                <DialogActions>
                  <Button
                    onClick={handleClose}
                    sx={{ color: (theme) => theme.palette.grey[500] }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="orange">
                    Send
                  </Button>
                </DialogActions>
              </>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}
