// import React, { useContext } from "react";
import DialogButton from '../../../../dialogs/company/CompanyDialog';
// import { CompanyContext } from '../../../../state/CompanyContext';
import { useAuth } from '../../../../../hooks/useAuth';
// import CompanyDataFetcher from '../../../../../data/CompanyDataFetcher'
// import { currentCompanyById } from '../../../../../utils/companies';
import styles from './AboutCompany.module.css';

function AboutCompany({ currentCompany, isBuyMode }) {

  // == state - START ==
  // const { state } = useContext(CompanyContext);
  // // const { currentCompanyIdStocks, companiesData } = state;
  // const { currentCompanyIdStocks } = state;

  // // const companiesData = CompanyDataFetcher({
  // //   apiEndpoint: "/api/companies-stocks",
  // //   companyIdType: currentCompanyIdStocks,
  // // });

  // const { company } = currentCompanyById(CompaniesStocksJson, currentCompanyIdStocks);
  // const currentCompany = company;
  // == state - END ==

  // == Login - START ==
  const { user } = useAuth();
  // == Login - END ==

  return(
  <div>
      {/* == Company - START ==  */}
      {currentCompany && (
        <div>
          <h2 className={styles.companyName}>{currentCompany.meta.companyName}</h2>
          <div>{user && (<span style={{textTransform: 'capitalize'}}>{user.fullname},</span>)} Let Me Tell You More About<br/> {currentCompany.meta.companyName}</div>
          <div className='separatorEmptyMedium'></div>
          <DialogButton 
            currentCompany={currentCompany} 
            user={user} 
            isBuyMode={isBuyMode}
          />
        </div>
      )}
      {/* == Company - END ==  */}
    </div>
  );

}

export default AboutCompany;

