import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function TalkDialog({open, onClose}) {

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Let's Talk</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill your details and we will contact you soon.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onClose}>Send</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}