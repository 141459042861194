import React, { useContext } from 'react';
import DownloadLink from '../../../../../global/DownloadLink';
import { useAuth } from '../../../../../../hooks/useAuth';
import ValooLogoOpacity from '../../../../../global/ValooLogoOpacity';
import { CompanyContext } from '../../../../../state/CompanyContext';

function CompletionBuy() {

  const { user } = useAuth();
  const { state: { currentCompanyIdStocks, companiesData } } = useContext(CompanyContext);

  const partnershipCertificateUrl = `/api/deal/files?companyId=${currentCompanyIdStocks}&fileName=certificate.pdf`;
  const invoiceUrl = `/api/deal/files?companyId=${currentCompanyIdStocks}&fileName=invoice.pdf`;
  const companyName = companiesData?.find((company) => company?.id === currentCompanyIdStocks)?.companyName
  return (
    <>
      <div className='separatorEmptyLarge'></div>

      <div>{user.fullname && `${user.fullname},`}</div>
      <div>
        We are glad to update that the share purchase of {companyName || 'verbit'} has been successfully completed.
        You can access your Partner Certificate in Valoo Partnership. {/*No.384750*/}
      </div>

      <div className='separatorEmptyLarge'></div>

      <div>
        <DownloadLink text='Partnership certificate' url={partnershipCertificateUrl} />
        <div className='separatorEmptySmall'></div>
        <DownloadLink text='Invoice' url={invoiceUrl} />
      </div>

      <div className='separatorEmptyLarge'></div>

      <div className='orangeFont fontBold'>
          Looking forward to meeting again<br />
          on our next investment…!
      </div>

      <div className='containerFlex flexEnd'>
        <ValooLogoOpacity />
      </div>
    </>
  );

}

export default CompletionBuy;