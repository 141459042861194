import React from "react";
import FundsTransferredTitle from '../../../../../../images/titles/funds-transferred.svg';
import ConfettiAnimation from "../../../../../../images/lotties/ConfettiAnimation";

function CompletionSell() {

  return (
    <>
      <h3>
        <img className='titleImage' src={FundsTransferredTitle} alt='The funds have been transferred, The deal is done!' />
      </h3>
      <ConfettiAnimation />
    </>
  );

}

export default CompletionSell;