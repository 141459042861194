// Version.jsx

import React from "react";

export default function Version() {

  const { version } = require('../../../../package.json');
  const lastModified = '2024.01.18';
  const time = '10:30'

  return(
    <input type="hidden" name="version" value={`v${version}-${lastModified} ${time}`} />
  );

}