import React from 'react';
import DownloadIcon from './icons/DownloadIcon';

function DownloadLink({ text, url }) {

  return (
    <a href={url} className="containerFlex linkDarkBlue" target="_blank" rel="noopener noreferrer">
      <DownloadIcon />
      <div className='separatorVerticalEmptySuperSmall'></div>
      <span>{text}</span>
    </a>
  );

}

export default DownloadLink;