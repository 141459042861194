import React, { useState, useEffect } from 'react';
import ArticleItemMobile from './ArticleItemMobile';
import styles from './StayUpdatedArticles.module.css'
import { useIsMobile } from '../../hooks/useIsMobile'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { apiInstance } from '../../services/api.service';

const StayUpdatedArticles = ({ companyId, parentWidth }) => {
  const [articles, setArticles] = useState([]);
  const carouselWidth = parentWidth * 0.82;

  // == Is Mobile - START ==
  const isMobile = useIsMobile();
  // == Is Mobile - END ==

  // == Scroll - START ==
  const scrollLeft = () => {
    const carousel = document.querySelector('.'+ styles.mask);
    carousel.scrollLeft -= 200; // Adjust the scroll distance as needed
  };

  const scrollRight = () => {
    const carousel = document.querySelector('.'+ styles.mask);
    carousel.scrollLeft += 200; // Adjust the scroll distance as needed
  };
  // == Scroll - END ==

  // == Stay Updated - START ==
  useEffect(() => {
    apiInstance.get('/api/company/shareholderupdates/' + companyId)
      .then((data) => {
        data = data.data
        if (data.shareholder && data.shareholder.length > 0) {
          setArticles(data.shareholder);
        }
        else {
          setArticles([]);
        }
      })
      .catch((error) => console.error('Error fetching articles:', error));
  }, [companyId])
  // == Stay Updated - END ==
  return (
    <>
      {articles.length > 0 ? (
      <div className='marginAuto primaryPadding'>
        <strong>Shareholders Updates</strong>
      </div>
      ) : null}
      <div className={styles.carousel} 
            style={isMobile ? null : { width: carouselWidth + 'px' }}>

          {!isMobile && articles.length > 0 && (
            <div className={styles.buttons}>
              <button className={styles.buttonLeft} onClick={scrollLeft}>
                <ArrowBackIosIcon />
              </button>
              <button className={styles.buttonRight} onClick={scrollRight}>
                <ArrowForwardIosIcon />
              </button>
            </div>
          )}

        <div class={styles.mask}>
          {articles.length > 0 ? (
            articles.map((article) => (
              <ArticleItemMobile key={article.id} article={article} />
            ))
          ) : null}
        </div>

      </div>
    </>
  )
};

export default StayUpdatedArticles;