import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import animationData from './confetti.json';

const ConfettiAnimation = () => {

  const animationId = 'confettiAnimationContainer';

  useEffect(() => {
    const animationContainer = document.getElementById(animationId);

    if (animationContainer) {
      const animation = lottie.loadAnimation({
        container: animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData, // Your Lottie animation JSON
      });

      return () => {
        animation.destroy(); // Cleanup when the component is unmounted
      };
    }
  }, []);

  return <div id={animationId}
              style={{ width: '300px', height: '300px' }}
          />;
};

export default ConfettiAnimation;
