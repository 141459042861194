import React from 'react';
import { IconButton } from '@mui/material';
import { Facebook, Twitter, LinkedIn, Mail } from '@mui/icons-material';
import { Tooltip } from '@mui/material'

function SocialIcons() {
  return (
    <div>
      {/* <IconButton href="#" color="primary">
        <Tooltip title="We're tweeting soon!">
          <Twitter />
        </Tooltip>
      </IconButton> */}
      <IconButton href="mailto:info@valoo.io" target="_blank" rel="noopener noreferrer" color="primary">
        <Mail />
      </IconButton>
      <IconButton href="https://il.linkedin.com/company/valoo-il" target="_blank" rel="noopener noreferrer" color="primary">
        <LinkedIn />
      </IconButton>
      <IconButton href="https://www.facebook.com/people/Valoo/100064014972964/" target="_blank" rel="noopener noreferrer" color="primary">
        <Facebook />
      </IconButton>
    </div>
  );
}

export default SocialIcons;
