import React from 'react';

function Content(props) {

  const { content } = props;

  return (
    <>
      {content.map((item, index) => (
        <React.Fragment key={index} >
          <div className='cardGray'>
            <strong>{item?.title}</strong>
            <div className='separatorEmptySmall'></div>
            <div>{item?.content}</div>
          </div>
          <div className='separatorEmptyLarge'></div>
        </React.Fragment>
      ))}
    </>
  );

}

export default Content;