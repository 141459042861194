import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

export default function HorizontalStepper({ steps, activeStep, isBuyMode }) {
  
  return (
    <Box sx={{ width: '100%' }} className='mobile-vertical-stepper'>
      <strong>Purchasing Steps</strong>
      <div className='separatorEmptyMedium'></div>
      <div style={{width: '70vw', overflow: 'hidden'}}>
        <div style={{ display: 'flex', overflowX: 'scroll', scrollSnapType: 'x mandatory', scrollbarWidth: 'none', '-ms-overflow-style': 'none' }}>
          <Stepper className='puchasing-steps-stepper' activeStep={activeStep} alternativeLabel>
            {steps.map((step) => (
              <Step key={step.label} sx={{minWidth:"110px"}}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
    </Box>
  );
  
}