import React, { useContext, useEffect } from 'react';
import styles from './CompaniesWallet.module.css';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { CompanyContext } from '../../../state/CompanyContext';
import UserWalletCompany from './UserWalletCompany';
import PublicAssetsEnum from '../../../../constants/PublicAssetsEnum';
import { apiInstance } from '../../../../services/api.service';

let loaded = false;
function CompaniesWallet({ type, companyIds, userWalletData }) {

  const isMobile = useIsMobile();

  const totalSteps = 6;

  // == state - START ==
  const { state, dispatch } = useContext(CompanyContext);

  const handleCompanyClick = (companyId) => {
    // Dispatch an action based on the 'type' prop
    if (type === 'stocks') {
      dispatch({ type: 'UPDATE_CURRENT_COMPANY_STOCKS', companyId });
    } else if (type === 'wallet') {
      dispatch({ type: 'UPDATE_CURRENT_COMPANY_WALLET', companyId });
    }
  };
  const { currentCompanyIdStocks, currentCompanyIdWallet, companiesData } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiInstance.get('/api/company/all');
        const data = response.data
        dispatch({ type: 'SET_COMPANIES_DATA', data });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dispatch]);
  // == state - END ==

  // Filter the companiesData based on the provided companyIds
  let filteredCompaniesData = [];
  if (companiesData?.length > 0) {
    const companiesArray = companiesData;
    filteredCompaniesData = companyIds
      ? companiesArray.filter((company) => companyIds.includes(company.id))
      : companiesArray;
  }

  // == Company data - START ==
  const getCompanyInfo = (companyIdToSearch) => {
    // Find the company with the specified ID in the array
    const foundCompany = userWalletData.find((company) => company.company_id === companyIdToSearch);
  
    // Check if a company with the specified ID was found
    if (foundCompany) {
      return foundCompany;
    } else {
      return null; // Return null if company ID is not found
    }
  };
  // == Company data - END ==


  // Select Default Company
  const onDefaultCompany = () => {
    const companyId = filteredCompaniesData?.[0]?.id;
    if (companyId) {
      dispatch({ type: 'UPDATE_CURRENT_COMPANY_WALLET', companyId });
    }
  };

  useEffect(() => {
    if (filteredCompaniesData?.length > 0 && !loaded) {
      loaded = true;
      onDefaultCompany();
    }
  }, [userWalletData, loaded]);
  // Select Default Company - END ==

  return (
    <>
      {isMobile ? (
        // Mobile version
        <div className={styles.carousel}>
          <div className={styles.mask}>
            <div className={styles.slider}>
              {filteredCompaniesData.map((company) => (
                <React.Fragment key={company.id}>
                  <div className='separatorVerticalEmptySmall'></div>
                  <div
                    className={`${styles.companyCard} ${
                      (type === 'wallet' && company.id === currentCompanyIdWallet) ? styles.companyCardActive : ''
                    }`}
                    onClick={() => handleCompanyClick(company.id)}
                  >
                    <div className='containerFlex'>
                      <img
                        className='squareLogo imageShadowGray'
                        src={`${PublicAssetsEnum.Url}public/images/companies/square/${company.id}.png`}
                        alt={company.companyName}
                      />
                      <div className='separatorVerticalEmptySmall'></div>
                      <div className='fontXLarger' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {company.companyName}
                      </div>
                    </div>
                    <div className='separatorEmptyMedium'></div>
                    <UserWalletCompany companyData={getCompanyInfo(company.id)} />
                  </div>
                  <div className='separatorEmptyMedium'></div>
                </React.Fragment>
              ))}
              <div className='separatorVerticalEmptySmall'></div>
            </div>
          </div>
          <div className='separatorEmptyMedium'></div>
        </div>
      ) : (
        // Desktop version
        <div>
          {filteredCompaniesData.map((company) => (
            <React.Fragment key={company.id}>
              <div
                className={`${styles.companyCard} ${
                  (type === 'wallet' && company.id === currentCompanyIdWallet)
                    ? styles.companyCardActive
                    : ''
                }`}
                onClick={() => handleCompanyClick(company.id)}
              >
                <div className='containerFlex'>
                  <img
                    className='squareLogo imageShadowGray'
                    src={`${PublicAssetsEnum.Url}public/images/companies/square/${company.id}.png`}
                    alt={company.companyName}
                  />
                  <div className='separatorVerticalEmptySmall'></div>
                  <div className='fontXLarger'>{company.companyName}</div>
                </div>
                <div className='separatorEmptyMedium'></div>
                <UserWalletCompany
                  currentCompanyIdWallet={currentCompanyIdWallet} 
                  companyData={getCompanyInfo(company.id)} 
                />

                {/* <div className='separatorEmptyMedium'></div>
                <div className='containerFlex alignItemsCenter'>
                  <div className='fontBold'>{calculatePercentage(getCompanyInfo(company.id).steps, totalSteps)}%</div>
                  <div className='separatorVerticalEmptySmall'></div>
                  <div className='progressContainer progressContainerWidth200'>
                    <div className='progressBar' style={{width: calculatePercentage(getCompanyInfo(company.id).steps, totalSteps) + '%'}}></div>
                  </div>
                </div> */}

              </div>
              <div className='separatorEmptyMedium'></div>
            </React.Fragment>
          ))}

        </div>
      )}
    </>
  );
}

export default CompaniesWallet;
