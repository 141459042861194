import React, { useEffect } from "react";
import Stocks from "./stocks/Stocks";
import Blog from "./blog/Blog";
import Wallet from "./wallet/Wallet";
import { useAuth } from "../../../hooks/useAuth";
import Divider from "@mui/material/Divider";
import Newsletter from "./newsletter/Newsletter";

export default function Main() {
  const { user } = useAuth();

  useEffect(() => {
    // Scroll to the top when the component mounts or updates
    window.scrollTo(0, 0);
  }, []);

  let content = <></>;

  if (user.status === "approved") {
    content = (
      <>
        <Stocks />
        <div className="separatorEmptyLarge secondBackground"></div>
        <Blog />
        <div className="separatorEmptyLarge secondBackground"></div>
        <Wallet />
        <div className="separatorEmptyLarge firstBackground"></div>
        <Newsletter />
        <div className="separatorEmptyLarge"></div>
      </>
    );
  } else if (user.status === "pending") {
    content = (
      <div className="secondBackground">
        <div className="maxWidth marginAuto">
          <div className="separatorEmptyLarge"></div>
          <div style={{ textAlign: "center" }}>
            <div>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_687_11415)">
                  <path
                    d="M28.7656 14.333L16.9826 12.525C16.6207 12.4687 16.251 12.5518 15.948 12.7574C15.6449 12.963 15.4311 13.2759 15.3496 13.633L11.3246 31.715C11.0613 32.8834 11.0446 34.0939 11.2757 35.2691C11.5067 36.4442 11.9805 37.5583 12.6666 38.54C13.763 40.1065 15.3393 41.2736 17.1576 41.865L15.1746 54.289L12.3746 53.881C12.1831 53.8503 11.9874 53.8577 11.7987 53.9026C11.61 53.9476 11.432 54.0293 11.2749 54.1431C11.1178 54.2568 10.9846 54.4004 10.883 54.5656C10.7814 54.7308 10.7133 54.9144 10.6826 55.106C10.652 55.2975 10.6593 55.4932 10.7043 55.6819C10.7493 55.8706 10.831 56.0486 10.9447 56.2057C11.0585 56.3628 11.202 56.496 11.3673 56.5976C11.5325 56.6992 11.7161 56.7673 11.9076 56.798L20.4236 58.081H20.6566C21.0039 58.0771 21.3391 57.9529 21.605 57.7295C21.8708 57.5061 22.051 57.1974 22.1146 56.856C22.1469 56.6643 22.1408 56.468 22.0965 56.2787C22.0522 56.0894 21.9707 55.9108 21.8567 55.7533C21.7426 55.5958 21.5984 55.4626 21.4324 55.3614C21.2664 55.2602 21.0819 55.1931 20.8896 55.164L18.0896 54.756L19.9556 42.39C21.8573 42.3304 23.6929 41.678 25.2056 40.524C26.1372 39.7782 26.9073 38.8505 27.4688 37.7975C28.0302 36.7445 28.3715 35.5881 28.4716 34.399L29.9876 15.967C30.0376 15.5887 29.9373 15.2058 29.7083 14.9006C29.4793 14.5953 29.1398 14.3919 28.7626 14.334L28.7656 14.333ZM17.8576 15.616L26.9576 17.016L26.6656 20.049H16.8656L17.8576 15.616ZM23.3406 38.131C22.7198 38.6165 22.0065 38.9704 21.2443 39.171C20.4821 39.3717 19.6871 39.4149 18.9076 39.298C18.1207 39.1871 17.3659 38.9116 16.6925 38.4895C16.0192 38.0673 15.4423 37.508 14.9996 36.848C14.5451 36.1923 14.2325 35.4489 14.0818 34.6654C13.9311 33.8819 13.9457 33.0755 14.1246 32.298L16.2246 22.965H26.4906L25.5576 34.048C25.4984 34.8468 25.2701 35.6238 24.8879 36.3277C24.5057 37.0316 23.9783 37.6463 23.3406 38.131Z"
                    fill="#FF7800"
                  />
                  <path
                    d="M50.2884 53.8801L47.4884 54.2881L45.6214 41.8641C47.6838 41.2039 49.4252 39.7972 50.5043 37.9198C51.5834 36.0423 51.9222 33.8294 51.4544 31.7151L47.4294 13.6331C47.3512 13.2742 47.1381 12.9591 46.8342 12.7529C46.5303 12.5467 46.1588 12.4651 45.7964 12.5251L34.0134 14.3331C33.6487 14.3938 33.3202 14.5895 33.0933 14.8814C32.8663 15.1732 32.7575 15.5397 32.7884 15.9081L34.3044 34.3401C34.3981 35.5309 34.7366 36.6896 35.2987 37.7435C35.8609 38.7974 36.6346 39.724 37.5714 40.4651C39.084 41.6195 40.9195 42.2722 42.8214 42.3321L44.6884 54.6981L41.8304 55.1651C41.6382 55.1942 41.4536 55.2613 41.2876 55.3625C41.1216 55.4637 40.9774 55.5969 40.8634 55.7544C40.7493 55.9119 40.6678 56.0905 40.6235 56.2798C40.5792 56.4691 40.573 56.6654 40.6054 56.8571C40.669 57.1985 40.8492 57.5072 41.1151 57.7306C41.381 57.954 41.7161 58.0782 42.0634 58.0821H42.2964L50.8124 56.7991C51.0046 56.77 51.1891 56.7028 51.3551 56.6017C51.5212 56.5005 51.6654 56.3673 51.7794 56.2098C51.8935 56.0523 51.975 55.8737 52.0193 55.6844C52.0635 55.495 52.0697 55.2988 52.0374 55.1071C51.9578 54.7178 51.7322 54.3738 51.4069 54.1457C51.0817 53.9175 50.6815 53.8225 50.2884 53.8801ZM44.8054 15.6161L45.7974 20.0491H35.9974L35.7644 17.0161L44.8054 15.6161ZM39.3224 38.1311C38.6925 37.6436 38.1742 37.027 37.802 36.3229C37.4298 35.6187 37.2125 34.8431 37.1644 34.0481L36.2314 22.9651H46.4974L48.5974 32.2981C48.7721 33.074 48.7851 33.8776 48.6357 34.6588C48.4863 35.44 48.1776 36.1821 47.7289 36.8388C47.2802 37.4955 46.7011 38.0527 46.0276 38.4758C45.3541 38.8989 44.6007 39.1788 43.8144 39.2981C43.0261 39.4506 42.2138 39.4249 41.4368 39.223C40.6598 39.0211 39.9377 38.648 39.3234 38.1311H39.3224Z"
                    fill="#FF7800"
                  />
                  <path
                    d="M32.79 4.125C32.79 3.73819 32.6364 3.36721 32.3629 3.09369C32.0893 2.82017 31.7184 2.6665 31.3315 2.6665C30.9447 2.6665 30.5738 2.82017 30.3002 3.09369C30.0267 3.36721 29.873 3.73819 29.873 4.125V6.575C29.873 6.96182 30.0267 7.3328 30.3002 7.60632C30.5738 7.87984 30.9447 8.0335 31.3315 8.0335C31.7184 8.0335 32.0893 7.87984 32.3629 7.60632C32.6364 7.3328 32.79 6.96182 32.79 6.575V4.125Z"
                    fill="#FF7800"
                  />
                  <path
                    d="M37.1062 9.95789C37.3316 9.95314 37.5532 9.89837 37.7549 9.79755C37.9565 9.69672 38.1332 9.55236 38.2723 9.37489L39.7303 7.39189C39.8468 7.23161 39.9302 7.04973 39.9757 6.85687C40.0213 6.664 40.0279 6.46401 39.9953 6.26855C39.9628 6.07308 39.8916 5.88606 39.786 5.71838C39.6804 5.5507 39.5425 5.40572 39.3803 5.29189C39.2248 5.17683 39.0475 5.09444 38.8593 5.04967C38.6711 5.0049 38.4758 4.99867 38.2851 5.03137C38.0944 5.06407 37.9123 5.13502 37.7497 5.23994C37.5872 5.34486 37.4476 5.48159 37.3393 5.64189L35.8813 7.62489C35.766 7.78045 35.6835 7.95774 35.6387 8.14606C35.5938 8.33438 35.5875 8.52982 35.6202 8.72063C35.6529 8.91143 35.7239 9.09364 35.829 9.25627C35.934 9.41889 36.0708 9.55857 36.2312 9.66689C36.4912 9.84144 36.7936 9.94134 37.1062 9.95789Z"
                    fill="#FF7800"
                  />
                  <path
                    d="M24.3918 9.31712C24.5264 9.49959 24.7023 9.64756 24.9052 9.7489C25.1081 9.85024 25.332 9.90206 25.5588 9.90012C25.8714 9.88357 26.1738 9.78267 26.4338 9.60812C26.7467 9.38045 26.9583 9.03963 27.0237 8.65824C27.0891 8.27684 27.003 7.885 26.7838 7.56612L25.3258 5.58312C25.2168 5.42089 25.0765 5.2821 24.9132 5.17491C24.7498 5.06772 24.5666 4.99429 24.3744 4.95896C24.1822 4.92363 23.9849 4.92711 23.7941 4.96918C23.6033 5.01126 23.4228 5.09108 23.2633 5.20395C23.1038 5.31683 22.9685 5.46048 22.8653 5.62644C22.7622 5.7924 22.6932 5.97733 22.6626 6.17032C22.632 6.36332 22.6403 6.56048 22.6871 6.75022C22.7338 6.93995 22.818 7.11842 22.9348 7.27512L24.3918 9.31712Z"
                    fill="#FF7800"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_687_11415">
                    <rect width="64" height="64" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <strong>
              Thank you for choosing to be part of the Valoo community!
            </strong>
            <div className="separatorEmptySmall"></div>
            <div>
              Our team is currently reviewing your registration, and we will
              approve it soon. <br />
              Please check back later for access to all the features of Valoo.
            </div>
          </div>
          <div className="separatorEmptyLarge"></div>
          <Divider variant="middle" />
          <div className="separatorEmptyLarge"></div>
          <Blog />
        </div>
      </div>
    );
  }
  return <>{content}</>;
}
