import React from 'react';
import styles from './Terms.module.css'
import Disclaimer from './disclaimer/Disclaimer';
import Copyright from './copyright/Copyright'
import BottomLinks from './links/BottomLinks'



// const preventDefault = (event) => event.preventDefault();

function Terms() {

  

  return(
    <div className={`maxWidth marginAuto`}>
      
      <div className={styles.container}>
        <BottomLinks />
        <Copyright />
      </div>

      <Disclaimer />
      
    </div>
  );

}

export default Terms;
