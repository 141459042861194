import React, { useState } from "react";
import ViewIcon from "../../../../../global/icons/ViewIcon";
import { Button } from "@mui/material";
import { useAuth } from "../../../../../../hooks/useAuth";
import {
  sendLeadToZapier,
  buildLeadData,
  leadTypeEnum,
} from "../../../../../../utils/zapier";
import { apiInstance } from "../../../../../../services/api.service";

function TransferBuy({ currentCompany, setActiveStep }) {
  const [disable, setdisable] = useState(true);
  const { user } = useAuth();

  //== api - START ==
  const handleClick = async () => {
    try {
      const response = await apiInstance.post("/api/user-wallet/stocks/buy/transfer", {
        company_id: currentCompany.id,
        steps: 3,
      });

      if (response.status >= 200 && response.status < 300) {
        setActiveStep(3);
        handleZapier();
        console.log("Stocks bought successfully");
      } else {
        console.error("Failed to buy stocks:", response.statusText);
      }
    } catch (error) {
      console.error("Error while buying stocks:", error.message);
    }
  };
  //== api - END ==

  //== Zapier - START ==
  const handleZapier = async () => {
    const leadContent = {
      dashboardType: "buy",
      userId: user.id,
      companyId: currentCompany.id,
      step: 2,
      phone: user?.phone || "",
    };
    const leadData = buildLeadData(leadTypeEnum.StocksBuy, leadContent);
    await sendLeadToZapier(leadData);
  };
  //== Zapier - START ==

  const wireInfoLink = `/api/company/stocks/buy/files?companyId=${currentCompany.id}&fileName=wire_info.pdf`;

  const onWireInformation = async () => {
    try {
      const response = await apiInstance.get(
        `/api/company/stocks/buy/files`, {
          params: {
            companyId: currentCompany.id,
            fileName: 'wire_info.pdf'
          },
          responseType: 'blob' // This is necessary to handle binary data like PDFs
        }
      );
    
      if (response.status >= 200 && response.status < 300) {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(pdfBlob);
        setdisable(false);
        window.open(url, "_blank");
      } else {
        setdisable(false);
      }
    } catch (error) {
      setdisable(false);
      console.error('Error fetching PDF:', error);
    }
  };

  return (
    <>
      <div>
        Transfer the consideration for the shares for the investment to be
        completed. Below are the account details for transferring the funds for
        the purpose of completing the transaction.
      </div>
      <div className="separatorEmptyLarge"></div>
      <div className="containerFlex">
        <div>
          <ViewIcon />
        </div>
        <div className="separatorVerticalEmptySuperSmall"></div>
        <div>
          <a
            className="linkDarkBlue"
            onClick={onWireInformation}
            rel="noopener noreferrer"
            style={{ cursor: "pointer" }}
          >
            View the wire information
          </a>
        </div>
      </div>

      <div className="separatorEmptyMedium"></div>

      <div className="containerFlex flexEnd">
        <Button
          variant="contained"
          color="orange"
          sx={{ textTransform: "none" }}
          style={{ borderRadius: 20 }}
          onClick={handleClick}
          disabled={disable}
        >
          Continue To Payment
        </Button>
      </div>
    </>
  );
}

export default TransferBuy;
