import React from "react";

export default function NounCelebrateIcon() {

  return(

    <svg width="90" height="90" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.5514 8.51246C39.8074 8.7685 40.0635 8.83246 40.3835 8.83246C40.7035 8.83246 40.9595 8.70455 41.2156 8.51246C41.6635 8.06455 41.6635 7.29641 41.2156 6.84852L39.5516 5.18457C39.1037 4.73665 38.3356 4.73665 37.8877 5.18457C37.4398 5.63248 37.4398 6.40062 37.8877 6.84852L39.5514 8.51246Z" fill="#FC751F"/>
      <path d="M45.504 6.27233H45.6961C46.3361 6.14442 46.7839 5.56839 46.6561 4.92839L46.2721 2.62444C46.1442 1.98444 45.5682 1.53655 44.9282 1.66444C44.2882 1.79233 43.8403 2.36839 43.9682 3.00839L44.3521 5.31233C44.4161 5.82421 44.9279 6.27233 45.504 6.27233Z" fill="#FC751F"/>
      <path d="M35.2639 13.6319L37.5679 14.0159H37.7599C38.336 14.0159 38.8478 13.6319 38.9121 12.992C39.04 12.352 38.5921 11.7759 37.9521 11.648L35.6481 11.2641C35.0081 11.1361 34.4321 11.5841 34.3042 12.2241C34.176 12.9278 34.6239 13.5678 35.2639 13.6319Z" fill="#FC751F"/>
      <path d="M57.1512 22.7842C56.7033 22.3363 55.9352 22.3363 55.4873 22.7842C55.0394 23.2321 55.0394 24.0002 55.4873 24.4481L57.1512 26.1121C57.4073 26.3681 57.6633 26.4321 57.9834 26.4321C58.3034 26.4321 58.5594 26.3042 58.8155 26.1121C59.2634 25.6642 59.2634 24.896 58.8155 24.4481L57.1512 22.7842Z" fill="#FC751F"/>
      <path d="M51.0073 25.0882C50.3673 25.2161 49.9194 25.7921 50.0473 26.4321L50.4312 28.7361C50.4952 29.3121 51.0073 29.76 51.5833 29.76H51.7754C52.4154 29.6321 52.8633 29.0561 52.7354 28.4161L52.3514 26.1121C52.2233 25.4082 51.6473 24.9602 51.0073 25.0882Z" fill="#FC751F"/>
      <path d="M61.3758 17.6646L59.0719 17.2807C58.4319 17.1527 57.8558 17.6007 57.7279 18.2407C57.6 18.8807 58.0479 19.4567 58.6879 19.5846L60.9919 19.9686H61.184C61.76 19.9686 62.2718 19.5846 62.3361 18.9446C62.4638 18.3686 62.0798 17.7927 61.3758 17.6646Z" fill="#FC751F"/>
      <path d="M58.176 13.1846L60.2881 11.0724C60.8641 10.4964 61.1202 9.79245 61.1202 9.02455C61.1202 8.25666 60.8002 7.55245 60.2881 6.97666L57.0241 3.71271C55.872 2.56061 54.0802 2.56061 52.9281 3.71271L50.816 5.82482C50.176 6.46482 50.0481 7.42482 50.368 8.19271L48.512 10.0488C47.552 11.0088 47.552 12.6088 48.512 13.5688L50.4959 15.5527C50.9439 16.0006 51.5838 16.2567 52.2238 16.2567C52.8638 16.2567 53.5038 16.0006 53.9517 15.5527L55.8078 13.6967C56.5759 14.0167 57.5359 13.8246 58.1759 13.1846L58.176 13.1846ZM55.6799 11.0727C55.296 11.0727 54.912 11.2006 54.592 11.5206L52.2881 13.8245C52.2241 13.8885 52.1602 13.8885 52.096 13.8245L50.112 11.8406C50.0481 11.7766 50.0481 11.7127 50.112 11.6485L52.416 9.34454C52.672 9.02454 52.8639 8.70454 52.8639 8.25665C52.8639 7.93665 52.736 7.5527 52.5439 7.29665L54.5278 5.3127C54.7199 5.12062 55.1039 5.12062 55.2957 5.3127L58.5597 8.57665C58.6876 8.70456 58.6876 8.89665 58.6876 8.96061C58.6876 9.02456 58.6876 9.21665 58.5597 9.34456L56.5757 11.3285C56.3199 11.2006 55.9999 11.0727 55.6799 11.0727Z" fill="#FC751F"/>
      <path d="M44.1596 15.2323C43.7757 14.8483 43.3275 14.6562 42.8157 14.6562C42.3036 14.6562 41.8557 14.8483 41.4717 15.2323L39.2318 17.4723C38.8478 17.8563 38.7838 18.4962 39.0397 18.9444L34.2397 23.2323C32.6397 24.7044 30.7836 25.8562 28.7997 26.6884L20.7997 30.0163C18.7518 30.8484 16.8957 32.1284 15.2957 33.6641L2.81566 46.1441C2.04777 46.912 1.59961 47.9363 1.59961 49.0241C1.59961 50.112 2.04752 51.1362 2.81566 51.9681L12.0317 61.1841C12.8638 62.0162 13.8877 62.4001 14.9756 62.4001C15.9996 62.4001 17.0877 62.0162 17.9196 61.1841L30.3996 48.6401C31.9356 47.104 33.2156 45.248 34.0475 43.1361L37.3753 35.1361C38.2075 33.1521 39.3593 31.2961 40.8314 29.6961L45.1193 24.8961C45.3114 25.024 45.5033 25.0882 45.7593 25.0882C46.0793 25.0882 46.3993 24.9603 46.5914 24.7682L48.8314 22.5282C49.5993 21.7603 49.5993 20.5442 48.8314 19.8403L44.1596 15.2323ZM28.7356 46.9763L16.1916 59.5203C15.5516 60.1603 14.3356 60.1603 13.6956 59.5203L4.47959 50.3043C4.15959 49.9843 3.9675 49.5364 3.9675 49.0243C3.9675 48.5764 4.15959 48.1283 4.47959 47.7443L17.0236 35.2003C18.3675 33.8564 19.9675 32.7682 21.7596 32.0642L24.5757 30.8482L24.8317 37.9522C24.8317 38.5922 25.3438 39.0401 25.9838 39.1043L33.0878 39.3603L31.8718 42.2403C31.1037 44.0325 30.0797 45.6325 28.7357 46.9764L28.7356 46.9763ZM45.7596 22.2723C45.5676 22.1444 45.3117 22.0802 45.0557 22.0802C44.7357 22.0802 44.4157 22.2081 44.2236 22.4642L39.1036 28.1602C37.5036 29.9523 36.2236 32.0641 35.2636 34.3042L34.0475 37.1202L27.1355 36.8642L26.8797 29.9522L29.6958 28.7361C31.9358 27.8401 33.9836 26.4961 35.8398 24.8961L41.5358 19.7761C41.7918 19.584 41.9197 19.264 41.9197 18.944C41.9197 18.688 41.8558 18.4319 41.7276 18.2401L42.8155 17.1522L46.7834 21.1201L45.7596 22.2723Z" fill="#FC751F"/>
      <path d="M19.6481 38.7207C19.2002 38.2728 18.432 38.2728 17.9841 38.7207L9.53613 47.1047C9.08822 47.5526 9.08822 48.3208 9.53613 48.7686L15.2321 54.4646C15.4242 54.6567 15.7442 54.7846 16.0642 54.7846C16.3843 54.7846 16.7042 54.6567 16.8963 54.4646L25.2803 46.0806C25.7283 45.6327 25.7283 44.8646 25.2803 44.4167L19.6481 38.7207ZM16.0641 51.9687L12.032 47.9366L18.752 41.2166L22.7841 45.2487L16.0641 51.9687Z" fill="#FC751F"/>
    </svg>

  );

}