import React, { useState } from 'react';
import { Box, Link } from '@mui/material';
import TalkDialog from '../../../../dialogs/talk/TalkDialog';

function FooterLinks() {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Box
      sx={{
        typography: 'subtitle1',
        fontWeight: '500',
        '& > :not(style) ~ :not(style)': {
          ml: 2,
        },
      }}
    >
      <Link href="https://valoo.io/about-us/" color="inherit" underline="none"  target="_blank" rel="noopener noreferrer">
        About Us
      </Link>
      {/* TODO: https://valoo.io/lets-talk/ <Link href="#" color="inherit" underline="none" onClick={handleClickOpen}>
        Let's Talk
      </Link> */}
      <TalkDialog open={open} onClose={handleClose} />
    </Box>
  );
}

export default FooterLinks;
