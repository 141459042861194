export function currentCompanyById(companies, id) {
  try {
    const currentCompany = companies.find((company) => company.id === id);
    if (currentCompany) {
      return { company: currentCompany, error: null };
    } else {
      return { company: null, error: `Company with ID ${id} not found.` };
    }
  } catch (error) {
    return { company: null, error: "An error occurred while searching for the company." };
  }
}