import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import { useAuth } from "../../hooks/useAuth";
import { ReactComponent as Logo } from "../../images/valoo-logo.svg";
import { useAuth } from "../../hooks/useAuth";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import UsersStatusEnum from "../../constants/UsersStatusEnum";
import UsersTypeEnum from "../../constants/UsersTypeEnum";
import { apiInstance } from "../../services/api.service";

const RegisterPage = () => {
  // const { register, errorMessage } = useAuth();
  const [errorMessage, setErrorMessage] = React.useState("");
  // const userTypeRef = useRef(null); // Create a ref for the UserType FormControl

  const { updateUserAttributes, user } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    const data = new FormData(event.currentTarget);

    const form = event.currentTarget;
    if (!form.checkValidity()) {
      // Mark the invalid fields with the "invalidInput" class
      const invalidElements = form.querySelectorAll(":invalid");
      invalidElements.forEach((element) => {
        element.classList.add("invalidInput");
      });

      setErrorMessage("Please fill in all required fields.");
      return; // Prevent form submission
    } else {
      // Remove the "invalidInput" class from all elements
      const allElements = form.elements;
      for (let i = 0; i < allElements.length; i++) {
        allElements[i].classList.remove("invalidInput");
      }
    }

    // Validate the form fields
    const fullName = data.get("fullName");
    const phone = data.get("phone");
    const userType = data.get("userType");

    if (!fullName || !phone || !userType) {
      setErrorMessage("Please fill in all required fields.");
      return; // Prevent form submission
    }

    const isPhoneValid = /^[+\d\s()-]+$/g.test(phone);

    if (!isPhoneValid) {
      setErrorMessage("Please enter a valid phone number.");
      return;
    }

    try {
      const newsletter = data.get("newsletter") === "on";

      const response = await apiInstance.post("/api/auth/register", {
        email: user?.email,
        fullName: fullName,
        phone: phone,
        userType: userType,
        newsletter: Boolean(newsletter),
      });
      if (response.status >= 200 && response.status < 300) {
        console.log("Registration successful:", response.data);
        updateUserAttributes({
          // username: fullName,
          fullName: fullName,
          newsletter: newsletter,
          userType: userType,
          status: UsersStatusEnum.Survey,
        });
      } else {
        // Registration failed, display the error message
        const data = await response.json();
        setErrorMessage(data.error || "An error occurred during registration");
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setErrorMessage("An error occurred during registration");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo alt="Valoo Logo" />
        <div className="separatorEmptyLarge"></div>
        <div className="separatorEmptyLarge"></div>
        <Typography component="h1" variant="h5">
          Join Valoo Community!
        </Typography>
        <div className="separatorEmptyMedium"></div>
        <div>Let's Personalize Your Experience</div>
        <div className="separatorEmptyLarge"></div>
        {errorMessage && (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="fullName"
            label="Full Name"
            id="fullName"
            autoComplete="name"
            onBlur={(e) => e.target.classList.remove("invalidInput")}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="phone"
            label="Phone"
            id="phone"
            autoComplete="tel"
            onBlur={(e) => e.target.classList.remove("invalidInput")}
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="userType-label">
              Are you a buyer or a seller?
            </InputLabel>
            <Select
              labelId="userType-label"
              id="userType"
              name="userType"
              label="Are you a buyer or a seller?"
              defaultValue="" // Set the default value to an empty string
            >
              <MenuItem value={UsersTypeEnum.Buyer}>Buyer</MenuItem>
              <MenuItem value={UsersTypeEnum.Seller}>Seller</MenuItem>
              <MenuItem value={UsersTypeEnum.BuyerAndSeller}>
                Buyer & Seller
              </MenuItem>
              <MenuItem value={UsersTypeEnum.Company}>Company</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={<Checkbox size="small" name="newsletter" defaultChecked />}
            label={
              <Typography variant="body2">
                I want to know about the latest news
              </Typography>
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, textTransform: "none" }}
            style={{ borderRadius: 20 }}
            color="orange"
          >
            Next
          </Button>

          <div className="separatorEmptyLarge"></div>

          {/* <Divider />
          <div className="separatorEmptyLarge"></div>
          <div style={{ textAlign: 'center' }}>
            {"Already have an account?"}
            <RouterLink to="/login">
              <Link href="#" variant="body2">
                <br />
                {"Log In"}
              </Link>
            </RouterLink>
          </div> */}
        </Box>
      </Box>
    </Container>
  );
};

export default RegisterPage;
