import React, { useState } from "react";
import "./Layout.css";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Main from "./main/Main";
import { useAuth } from "../../hooks/useAuth";
import SurveyPage from "../survey/SurveyPage";
import RegisterPage from "../login/RegisterPage";
import UsersStatusEnum from "../../constants/UsersStatusEnum";
import { Box, Button } from "@mui/material";
import Loader from "../UI/Loader";

function Layout() {
  const { user, logout, logoutLoading } = useAuth();

  let content;

  switch (user?.status) {
    case UsersStatusEnum.Survey:
      content = <SurveyPage />;
      break;
    case UsersStatusEnum.Pending:
    case UsersStatusEnum.Approved:
      content = (
        <div className="parent">
          <Header />
          <Main />
          <Footer />
        </div>
      );
      break;
    // 'registration' and default case render the same content
    case UsersStatusEnum.Registration:
    default:
      content = <RegisterPage />;
  }

  if (!user) {
    return <Loader />;
  }
  return (
    <Box>
      {user?.status === UsersStatusEnum?.Registration ? (
        <Box mx={2} my={2} sx={{ display: "flex", justifyContent: "end" }}>
          <Button disabled={logoutLoading} variant="contained" onClick={logout}>
            {logoutLoading ? "Logging out..." : "Logout"}
          </Button>
        </Box>
      ) : (
        <></>
      )}
      <Box>{content}</Box>
    </Box>
  );
}

export default Layout;
