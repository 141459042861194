import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

export default function VerticalLinearStepper({ steps, activeStep, isBuyMode }) {

  return (
    <Box sx={{ maxWidth: 800 }} className='desktop-vertical-stepper'> {/* max-width: 800px; */}
      <strong>Purchasing Steps</strong>
      <div className='separatorEmptyMedium'></div>
      <Stepper className='puchasing-steps-stepper' activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>
              <strong>{step.label}</strong>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );

}
