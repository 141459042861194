import React from "react";
import BuyStocksImage from "../../../../images/titles/buy-stocks.svg";
import SellStocksImage from "../../../../images/titles/sell-stocks.svg";
import Link from "@mui/material/Link";
import { useAuth } from "../../../../hooks/useAuth";

function ChangeButton({ isBuyMode, handleToggleMode }) {
  const { user } = useAuth();

  return (
    <div
      className="maxWidth marginAuto"
      style={{ display: "flex", alignItems: "center" }}
    >
      <h1>
        {isBuyMode === true ? (
          <img className="titleImage" src={BuyStocksImage} alt="Buy Stocks" />
        ) : (
          <img className="titleImage" src={SellStocksImage} alt="Sell Stocks" />
        )}
      </h1>
      <div className="separatorVerticalEmptySmall"></div>
      <div style={{ flex: 1 }}>
        {user.userType === "buyerandseller" && (
          <Link
            href="#"
            onClick={handleToggleMode}
            sx={{ typography: "caption", fontWeight: "500" }}
          >
            Change to {isBuyMode ? "Sell" : "Buy"}
          </Link>
        )}
      </div>
    </div>
  );
}

export default ChangeButton;
