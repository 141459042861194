import React from 'react';
import styles from './Header.module.css';

function Header(props) {

  const {
    header: {
      itemA: { title: itemATitle, content: itemAContent },
      itemB: { title: itemBTitle, content: itemBContent },
      itemC: { title: itemCTitle, content: itemCContent },
      itemD: { title: itemDTitle, content: itemDContent },
      itemE: { title: itemETitle, content: itemEContent }
    }
  } = props;

  return (
    <div className={`${styles.container}`}>
      <div>
        <div>{itemATitle}</div>
        <div>{itemAContent}</div>
      </div>
      <div>
        <div>{itemBTitle}</div>
        <div>{itemBContent}</div>
      </div>
      <div>
        <div>{itemCTitle}</div>
        <div>{itemCContent}</div>
      </div>
      <div>
        <div>{itemDTitle}</div>
        <div>{itemDContent}</div>
      </div>
      <div>
        <div>{itemETitle}</div>
        <div>{itemEContent}</div>
      </div>
    </div>
  )

}

export default Header;
