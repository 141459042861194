import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { formatWithThousandsSeparators } from '../../utils/strings';

export default function InvestmentPriceSlider({ value, onChange }) {
  return (
    <Box sx={{ width: 300, margin: 'auto', textAlign: 'center' }}>
      <Typography id="investment-price-slider" gutterBottom>
        Optimal Investment Amount for You
      </Typography>
      <Slider
        aria-labelledby="investment-price-slider"
        value={value}
        onChange={onChange}
        step={1000}
        min={100000}
        max={2000000}
      />
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{`$${formatWithThousandsSeparators(value)}`}</Typography>
    </Box>
  );
}
