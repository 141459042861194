import React, { useContext } from 'react';
import Header from './Header';
import Main from './Main';
import { CompanyContext } from '../../../state/CompanyContext';
import { currentCompanyById } from '../../../../utils/companies';

const CompanyDetails = () => {

  const { state } = useContext(CompanyContext);
  const { companiesDataWallet, currentCompanyIdWallet } = state;

  const { company } = currentCompanyById(companiesDataWallet, currentCompanyIdWallet);
  const currentCompanyData = company;

  return (
    <div style={{maxWidth:"100vw"}}>

      {currentCompanyData && (
        <>
          <Header header={currentCompanyData.header} />
          <Main main={currentCompanyData.main} />
        </>
      )}

      {/* <h1>{companyName}</h1>
      <p>Category: {category}</p> */}
      <div className='separatorEmptyLarge'></div>
      <div className='separatorEmptyLarge'></div>
    </div>
  );
};

export default CompanyDetails;
