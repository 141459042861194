import React from 'react';
import Header from './Header';
import styles from './TabContentD.module.css';
import Content from './Content';

function TabContentD({ content }) {

  return(
    <>
      <div className={styles.container}>
        <div className={styles.firstDiv}>
          <Content content={content} />
        </div>
      </div>
    </>
  );

}

export default TabContentD;