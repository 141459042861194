import React from 'react';
import styles from './TabContentC.module.css'

function TabContentC({ tab }) {
  const { images, content } = tab;

  const tabCItem = content?.map(item => {
    if (!item?.content?.trim()?.length || !item?.title?.trim()?.length) {
      return true; 
    } else {
      return false;
    }
  });
  

  return (
    <>
      {content.map((item, index) => (
        <React.Fragment key={index} >
        {item?.length && <div className='cardGray'>
            <strong>{item?.title}</strong>
            <div className='separatorEmptySmall'></div>
            <div>{item?.content}</div>
          </div>}
          {item?.length && <div className='separatorEmptyLarge'></div>}
        </React.Fragment>
      ))}

      {!tabCItem && <div className='separatorEmptyLarge'></div>}

      <div className={styles.imageContainer}>
        {images.map((image, index) => (
          <React.Fragment key={index} >
            <div className={styles.box}>
              <img src={image} className={styles.image} alt={`${index}`} onError={(e) => { e.target.style.display = 'none'; }}/>
            </div>
            <div className='separatorEmptyLarge'></div>
          </React.Fragment>
        ))}
      </div>
      
    </>
  );
}

export default TabContentC;
