import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function AccessibilityDialog({ open, handleClose }) {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Accessibility Statement
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            We strive to ensure that our website is accessible to individuals with disabilities and is compliant with web accessibility standards. We are committed to providing an inclusive and user-friendly experience for all users.
          </Typography>
          <Typography gutterBottom>
            We are continuously working to improve the accessibility of our website and strive to adhere to WCAG (Web Content Accessibility Guidelines) 2.1 Level AA standards. We regularly conduct accessibility audits and testing to identify and address any potential accessibility issues.
          </Typography>
          <Typography gutterBottom>
            If you encounter any accessibility barriers or have any suggestions for improving the accessibility of our website, please contact us. We appreciate your feedback and are committed to ensuring equal access and usability for all users.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

AccessibilityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

function DialogButton() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link href='#' variant="outlined" onClick={handleClickOpen}>
        Accessibility Statement
      </Link>
      <AccessibilityDialog open={open} handleClose={handleClose} />
    </div>
  );
}

export default DialogButton;
