import { apiInstance } from "../services/api.service";

/**
 * Example: 
import { sendLeadToZapier, buildLeadData, leadTypeEnum } from "../yourpath/utils/zapier";

const leadData = buildLeadData(leadTypeEnum.SignUp, leadContent);
await sendLeadToZapier(leadData);
 */
async function sendLeadToZapier(leadData) {
  try {
    const fullURL = 'https://hooks.zapier.com/hooks/catch/1156877/3188qwj/';

    const response = await apiInstance.post(fullURL, leadData);
    if (response.status >= 200 && response.status < 300) {
      // Dev logs
      // console.log('Webhook request was successful!');
      // console.log(leadData);
    } else {
      console.log('Webhook request was failed.');
    }
  } catch (error) {
    console.error('Error fetching webhook:', error);
  }
}

/**
 * Builds the lead data object based on the provided lead type and content.
 *
 * @param {string} leadType - The type of the lead. Possible values: "OfferingSell", "OfferingBuy", "CompanyAdded", "UserRegister", "DocRequest".
 * @param {object} leadContent - The content of the lead, represented as a JSON object.
 * @returns {object} - The lead data object containing the leadType and leadContent.
 */
function buildLeadData(leadType, leadContent) {
  
  const leadData = {
    leadType: leadType,
    leadContent: leadContent
  };

  return leadData;
}

const leadTypeEnum = {
  StocksBuy: 'StocksBuy',
  StocksSell: 'StocksSell',
  SurveyBuyer: 'SurveyBuyer',
  SurveySeller: 'SurveySeller',
  SurveyBuyerAndSeller: 'SurveyBuyerAndSeller',
  SurveyCompany: 'SurveyCompany',
}

export { sendLeadToZapier, buildLeadData, leadTypeEnum };
