import React, { createContext, useReducer, useEffect } from 'react';
import { apiInstance } from '../../services/api.service';

const CompanyContext = createContext();

const companyReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_CURRENT_COMPANY_STOCKS': {
      return {
        ...state,
        currentCompanyIdStocks: action.companyId,
      };
    }
    case 'UPDATE_CURRENT_COMPANY_SELL_STOCKS': {
      return {
        ...state,
        currentSellCompanyIdStocks: action.companyId,
      };
    }
    case 'UPDATE_CURRENT_COMPANY_WALLET': {
      return {
        ...state,
        currentCompanyIdWallet: action.companyId,
      };
    }
    case 'SET_COMPANIES_DATA': {
      return {
        ...state,
        companiesData: action.data.companiesData,
      };
    }
    case 'SET_COMPANY_DATA_STOCKS': {
      return {
        ...state,
        companiesDataStocks: action.data.companiesDataStocks,
      };
    }
    case 'SET_COMPANY_DATA_WALLET': {
      return {
        ...state,
        companiesDataWallet: action.data.companiesDataWallet,
      };
    }
    default: {
      return state;
    }
  }
};

const CompanyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(companyReducer, {
    currentCompanyIdStocks: 0,
    currentCompanyIdWallet: 0,
    currentSellCompanyIdStocks: 0,
    companiesData: [],
    companiesDataStocks: [],
    companiesDataWallet: [],
  });

  useEffect(() => {
    // Fetch data from the API
    const fetchCompaniesData = async () => {
      try {
        const response = await apiInstance.get('/api/company/all');
        const data = response.data

        // Dispatch an action to update the companiesData in the context state
        dispatch({ type: 'SET_COMPANIES_DATA', data });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchCompanyDataStocks = async () => {
      try {
        const response = await apiInstance.get('/api/company/company_data_stocks');
        const data = response.data

        // Dispatch an action to update the companiesDataStocks in the context state
        dispatch({ type: 'SET_COMPANY_DATA_STOCKS', data });
      } catch (error) {
        console.error('Error fetching company_data_stocks data:', error);
      }
    };

    const fetchCompanyDataWallet = async () => {
      try {
        const response = await apiInstance.get('/api/company/company_wallet_data');
        const data = response.data

        // Dispatch an action to update the companiesDataStocks in the context state
        dispatch({ type: 'SET_COMPANY_DATA_WALLET', data });
      } catch (error) {
        console.error('Error fetching company_data_stocks data:', error);
      }
    };

    // Call the fetch functions when the component mounts
    fetchCompaniesData();
    fetchCompanyDataStocks();
    fetchCompanyDataWallet();

  }, []); // Empty dependency array ensures that this effect runs only once on mount

  return (
    <CompanyContext.Provider value={{ state, dispatch }}>
      {children}
    </CompanyContext.Provider>
  );
};

export { CompanyContext, CompanyProvider };
