import React from 'react';
import { Typography } from '@mui/material';
import styles from './Disclaimer.module.css'


function Disclaimer() {

  return(
    <div className={styles.container}>
      <Typography variant="caption" display="block" gutterBottom color="grey">
        Valoo’s investors are institutional or accredited investors only. The information presented on the website does not constitute an offer of securities or an invitation to receive such offers, but only general and principled publication and presentation. Each offer to purchase securities will be made to a maximum of 35 offerees, as well as to accredited investors, who will be selected in a negotiation procedure between the seller and the buyer of the securities, in accordance with sections 15 (a) (1), 15 (a) (4) and 15 (A) (7) of the Israeli Securities Law, 5768-1968. The aforesaid on the website does not constitute investment advice and / or a substitute for investment advice that takes into account the needs of each person. Offer and purchase of securities on the website will be made on the basis of complete and detailed documents between the parties. For details regarding the risks involved in purchasing securities, see Terms of Use.
      </Typography>
    </div>
  );

}

export default Disclaimer;
