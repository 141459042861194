import React from 'react';
import Header from './Header';
import styles from './TabContentA.module.css';
import Content from './Content';

function TabContentA({ tab, header }) {

  const { images, content } = tab;

  return(
    <>
      <div className={styles.container}>
        <div className={styles.firstDiv}>
          <Content content={content} />
        </div>
        <div className={styles.secondDiv}>
          <Header header={header} />
          <div className='separatorEmptyLarge'></div>
        </div>
      </div>

      <div className={styles.imageContainer}>
        {images.map((image, index) => (
          <React.Fragment key={index} >
            <div className={styles.box}>
              <img src={image} className={styles.image} alt={`${index}`} onError={(e) => { e.target.style.display = 'none'; }}
              />
            </div>
            <div className='separatorEmptyLarge'></div>
          </React.Fragment>
        ))}
      </div>

    </>
  );

}

export default TabContentA;