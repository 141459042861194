import React from 'react';
import styles from './CompanyLogo.module.css'
import PublicAssetsEnum from '../../../constants/PublicAssetsEnum';

function CompanyLogo({ currentCompany }) {

  return(
    <>
      <div className={styles.container}>
        <div className={styles.image}>
          <div className={styles.imageShadow}>
            <img src={PublicAssetsEnum.Url + 'public/images/companies/square/' + currentCompany.id + '.png'} className='squareLogo' alt='Square Logo' />
          </div>
        </div>
        <div className={styles.title}>
          {currentCompany.meta.companyName}
        </div>
        <div className={styles.link}>
          <a href={currentCompany.meta.website} target="_blank" rel="noopener noreferrer" className='fontSmall darkBlueFont'>Visit Website</a>
        </div>
      </div>
    </>
  );

}

export default CompanyLogo;
