import { createContext, useContext, useMemo, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { useAuth0 } from "@auth0/auth0-react";

const AuthContext = createContext({
  user: {},
  updateUserAttributes: (newProperties) => {},
  logout: async () => {},
  logoutLoading: false,
  setLogoutLoading: (value) => {},
});

export const AuthProvider = ({ children }) => {
  const { logout: oauthLogout } = useAuth0();
  const [user, setUser] = useState(null)
  const [errorMessage, setErrorMessage] = useState("");
  const [logoutLoading, setLogoutLoading] = useState(false);

  const logout = async () => {
    try {
      setLogoutLoading(true);
      await oauthLogout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
      localStorage.removeItem("token");
    } catch (error) {
    } finally {
      setLogoutLoading(false);
    }
  };

  const updateUserAttributes = (newAttributes) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...newAttributes,
    }));
  };

  const value = useMemo(
    () => ({
      user,
      logout,
      errorMessage, // Include the error message in the context
      updateUserAttributes,
      logoutLoading,
      setLogoutLoading,
    }),
    [user, errorMessage]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
