import React, { useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { ReactComponent as Logo } from "../../images/valoo-logo.svg";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CompanySurvey from "./CompanySurvey";
import SellerSurvey from "./SellerSurvey";
import BuyerSurvey from "./BuyerSurvey";
import BuyerAndSellerSurvey from "./BuyerAndSellerSurvey";
import Button from "@mui/material/Button";

const SurveyPage = () => {
  const { user, updateUserAttributes, logout } = useAuth();
  const userType = user.userType ?? user.usertype;
  const updateUserStatus = () => {
    updateUserAttributes({
      status: "pending",
    });
  };

  const onSaveSeller = () => {
    updateUserStatus();
  };

  const onSaveBuyer = () => {
    updateUserStatus();
  };

  const onSaveBuyerAndSeller = () => {
    updateUserStatus();
  };
  const handleLogout = async () => {
    logout();
  };
  useEffect(() => {
    // Scroll to the top when the component mounts or updates
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Logo alt="Valoo Logo" />
      {userType === "company" && (
        <Button
          onClick={handleLogout}
          sx={{ position: "absolute", top: 10, right: 0 }}
          variant="contained"
        >
          Logout
        </Button>
      )}
      {userType === "seller" && (
        <>
          <SellerSurvey onSave={onSaveSeller} />
        </>
      )}

      {userType === "buyer" && (
        <>
          <BuyerSurvey onSave={onSaveBuyer} />
        </>
      )}

      {userType === "buyerandseller" && (
        <>
          <BuyerAndSellerSurvey onSave={onSaveBuyerAndSeller} />
        </>
      )}

      {userType === "company" && <CompanySurvey />}
    </Box>
  );
};

export default SurveyPage;
