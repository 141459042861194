import React from 'react';
import styles from './Main.module.css';

function Paragraphs(props) {

  const { paragraphA, paragraphB} = props;

  const { title: paragraphATitle, content: paragraphAContent } = paragraphA;
  const { title: paragraphBTitle, content: paragraphBContent } = paragraphB;

  return(
    <div className={styles.content}>
      <div>
        <strong>{paragraphATitle}</strong>
        <div>{paragraphAContent}</div>
      </div>
      <div className='separatorEmptyLarge'></div>
      <div>
        <strong>{paragraphBTitle}</strong>
        <div>{paragraphBContent}</div>
      </div>
    </div>
  );

}

export default Paragraphs;
