import React, { useState, useEffect } from 'react';
import LoadingDots from '../../../../../global/LoadingDots';
import DownloadLink from '../../../../../global/DownloadLink';
import { apiInstance } from '../../../../../../services/api.service';

function JoinedBuy({ companyName, companyId }) {


  //== URLs - START ==
  const userFilePath = `/api/deal/files?companyId=${companyId}&fileName=`;
  const urlRegistration = userFilePath + 'registration.pdf';
  const urlSubscription = userFilePath + 'subscription.pdf';
  const urlLpa = userFilePath + 'lpa.pdf';
  //== URLs - END ==

  //== Stocks - START ==
  const [numberOfStocks, setNumberOfStocks] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiInstance.get(`/api/user-wallet/user-wallet-buy/company/${companyId}`);
        
        if (!response.status <=250) {
          throw new Error('Network response was not ok');
        }

        const data =  response.data;
        setNumberOfStocks(data.numberOfStocks[0].number_of_stocks);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [companyId, companyName]);
  //== Stocks - END ==

  return (
    <>
      <div>
        Get ready for closing.
      </div>
      <div className='separatorEmptyLarge'></div>
      <div>
        <strong>
          The consideration for the purchase of {numberOfStocks} shares of {companyName} has been deposited and closing of the transaction will be completed soon.
        </strong>
      </div>

      <div className='separatorEmptyLarge'></div>
      <div className='separatorEmptyLarge'></div>

      <div className='containerFlex'>
        <strong>
        Soon to be completed
        </strong>
        <div className='separatorVerticalEmptyVerySmall'></div>
        <LoadingDots />
      </div>

      <div className='separatorEmptyLarge'></div>
      <div className='separatorEmptyLarge'></div>

      <div>
        <DownloadLink text='Partnership registration certificate' url={urlRegistration} />
        <div className='separatorEmptySmall'></div>
        <DownloadLink text='Signed Subscription agreement' url={urlSubscription} />
        <div className='separatorEmptySmall'></div>
        <DownloadLink text='LPA' url={urlLpa} />
      </div>
    </>
  );

}

export default JoinedBuy;