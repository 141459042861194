import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import BasicTabs from "./BasicTabs";
import { sendLeadToZapier, buildLeadData } from "../../../utils/zapier";
import CompanyLogo from "./CompanyLogo";
import styles from "./CompanyDialog.module.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CompanyDialog({ open, handleClose, currentCompany, user, isBuyMode }) {
  const [zapierReceived, setZapierReceived] = useState(false);

  const handleZapierClick = async () => {
    const leadType = "companyInfoRequest";
    let dashboardType = "sell";
    if (isBuyMode) {
      dashboardType = "buy";
    }
    const leadContent = {
      userId: user.id,
      companyId: currentCompany.id,
      dashboardType: dashboardType,
      phone: user?.phone || "",
    };
    const leadData = buildLeadData(leadType, leadContent);
    await sendLeadToZapier(leadData);
    setZapierReceived(true);
  };

  const handleCloseWithReset = () => {
    setZapierReceived(false); // Reset zapierReceived to false before closing the dialog
    handleClose(); // Call the original handleClose function
  };

  return (
    <div>
      {currentCompany && (
        <BootstrapDialog
          onClose={handleCloseWithReset}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth={"xl"}
          maxWidth={"xl"}
          className="about-company-dialog"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseWithReset}
          >
            <div className={styles.container}>
              <CompanyLogo currentCompany={currentCompany} />

              <div style={{ marginRight: "40px" }}>
                {zapierReceived ? (
                  <div className="fontReceived fontMedium">
                    Your request received.
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    color="orange"
                    sx={{ textTransform: "none" }}
                    style={{ borderRadius: 20 }}
                    onClick={handleZapierClick}
                  >
                    Contact Us For More Details
                  </Button>
                )}
              </div>
            </div>
          </BootstrapDialogTitle>
          <DialogContent>
            {/* Removed: dividers  */}
            <BasicTabs currentCompany={currentCompany} />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseWithReset}>
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>
      )}
    </div>
  );
}

CompanyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

function DialogButton({ currentCompany, user, isBuyMode }) {
  const { tabs } = currentCompany;
  const { taba, tabb, tabc, tabd } = tabs;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {(taba || tabb || tabc || tabd) && (
        <>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
            style={{ borderRadius: 20, minWidth: "280px" }}
            onClick={handleClickOpen}
          >
            Read More About &nbsp;
            {currentCompany ? currentCompany.meta.companyName : "The Company"}
          </Button>
          <CompanyDialog
            open={open}
            handleClose={handleClose}
            currentCompany={currentCompany}
            user={user}
            isBuyMode={isBuyMode}
          />
        </>
      )}
    </div>
  );
}

export default DialogButton;
