import React, { useContext } from 'react';
import styles from './BuyAndSellStocksMobile.module.css';
import WhatDoNowImage from '../../../../images/titles/what-do-now.svg';
import HorizontalStepper from './HorizontalStepper';
import AboutCompany from './companies/AboutCompany';
import { getStepsArray } from './steps';
import { useAuth } from '../../../../hooks/useAuth';
import { CompanyContext } from '../../../state/CompanyContext';
import { sendLeadToZapier, buildLeadData } from '../../../../utils/zapier';
import OfferingBuy from './steps/buy/OfferingBuy';
import SigningBuy from './steps/buy/SigningBuy';
import TransferBuy from './steps/buy/TransferBuy';
import TransferBuyHands from './steps/buy/TransferBuyHands';
import FundsBuy from './steps/buy/FundsBuy';
import JoinedBuy from './steps/buy/JoinedBuy';
import CompletionBuy from './steps/buy/CompletionBuy';
import CompletionBuyCheers from './steps/buy/CompletionBuyCheers';
import OfferingSell from './steps/sell/OfferingSell';
import ApprovingSell from './steps/sell/ApprovingSell';
import SigningSell from './steps/sell/SigningSell';
import PendingSell from './steps/sell/PendingSell';
import TransactionSell from './steps/sell/TransactionSell';
import CompletionSell from './steps/sell/CompletionSell';

function BuyAndSellStocksMobile({ isBuyMode, currentCompany, activeStep, setActiveStep }) {

  // == Lead - START ==
  const { user } = useAuth() || {};
  const { state: { currentCompanyIdStocks } } = useContext(CompanyContext) || {};

  const [leadContentState, setLeadContentState] = React.useState(0);

  const updateLeadContent = (amountOfStocks, pricePerShare) => {
    // Assuming you have the leadContent state in the parent component
    setLeadContentState({
      ...leadContentState,
      amountOfStocks: amountOfStocks,
      pricePerShare: pricePerShare,
    });
  };
   // == Lead - END ==

  const steps = getStepsArray();

  // Buy
  const renderBuyStepsComponent = () => {
    switch (activeStep) {
      case 0:
        return <OfferingBuy
                  currentCompany={currentCompany}
                  setActiveStep={setActiveStep} />;
      case 1:
        return <SigningBuy setActiveStep={setActiveStep} currentCompany={currentCompany}/>;
      case 2:
        return <TransferBuy
                  currentCompany={currentCompany}
                  setActiveStep={setActiveStep} />;
      case 3:
        return <FundsBuy
                  companyId={currentCompany.id} />;
      case 4:
        return <JoinedBuy 
                  companyName={currentCompany.meta.companyName} 
                  companyId={currentCompany.id} />;
      case 5:
        return <CompletionBuy />;
      default:
        return <div>default</div>;
    }
  };

  // Sell
  const renderSellStepsComponent = () => {
    switch (activeStep) {
      case 0:
        return <OfferingSell 
                  currentCompany={currentCompany}
                  setActiveStep={setActiveStep} />;
      case 1:
        return <ApprovingSell />;
      case 2:
        return <SigningSell 
                  companyId={currentCompany?.id}
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  />;
      case 3:
        return <PendingSell 
                  companyId={currentCompany.id} />;
      case 4:
        return <TransactionSell />;
      case 5:
        return <CompletionSell />;
      default:
        return <div>default</div>;
    }
  };

  const handleButtonClick = () => {
    switch (activeStep) {
      case 0:
        handleOfferingClick();
        return;
      case 1:
        return null;
      case 2:
        return null;
      case 3:
        return null;
      case 4:
        return null;
      case 5:
        return null;
      default:
        return null;
    }
  };

  const nextStepButtonText = () => {
    switch (activeStep) {
      case 0:
        return "Click Here To Proceed";
      case 1:
        return "Click Here To Proceed";
      case 2:
        return "Click Here To Proceed";
      case 3:
        return "Click Here To Proceed";
      case 4:
        return "Click Here To Proceed";
      case 5:
        return "Click Here To Proceed";
      default:
        return "Click Here To Proceed";
    }
  };

  const handleOfferingClick = async () => {
    //setActiveStep(1);
    if (activeStep === 0) {
      const { amountOfStocks, pricePerShare } = leadContentState;
      let dashboardType = "sell";
      let leadType = "OfferingSell";
      if (isBuyMode) {
        leadType = "OfferingBuy";
        dashboardType = "buy";
      }
      const leadContent = {
        userId: user?.user_id,
        companyId: currentCompanyIdStocks,
        amountOfStocks: amountOfStocks,
        pricePerShare: pricePerShare,
        dashboardType: dashboardType,
        phone:user?.phone || ""
      };
      const leadData = buildLeadData(leadType, leadContent);
      await sendLeadToZapier(leadData);
    }
    const step = (prevActiveStep) => prevActiveStep + 1;
    setActiveStep(step);
  }

  return (
    <>
      <div className={`firstBackground ${styles.card}`}>

        <div className={styles.inCardContainer}>
          <div>
            <AboutCompany 
              currentCompany={currentCompany} 
              isBuyMode={isBuyMode}
            />
            <div className='separatorEmptyLarge'></div>
            <div className='separatorEmptySmall'></div>
            <HorizontalStepper 
              isBuyMode={isBuyMode} 
              steps={steps} 
              activeStep={activeStep} 
            />

          </div>
          <div>
            <div className='dividerHorizontal'></div>
            
            <div>
              {(activeStep === 2 && isBuyMode) && <TransferBuyHands companyName={currentCompany.meta.companyName} />}
              {(activeStep === 5 && isBuyMode) && <CompletionBuyCheers />}
              {activeStep !== 5 && (
                <h3>
                  <img className='titleImage' src={WhatDoNowImage} alt='What Do I Need To Do Now?' />
                </h3>
              )}
              {isBuyMode ? 
                renderBuyStepsComponent()
                :
                renderSellStepsComponent()
              }
            </div>

          </div>
        </div>

      </div>
    </>
  );
}

export default BuyAndSellStocksMobile;
