import React from "react";
import { CalendarMonth } from '@mui/icons-material';
import Blog from "../layout/main/blog/Blog";
import { Box } from "@mui/material";

function CompanySurvey() {

  const url = 'https://calendly.com/hadar-9s8/30min?embed_domain=valoo.io&embed_type=Inline&text_color=011e37&primary_color=011E37';

  return (
    <>
      <div className="separatorEmptyLarge"></div>
      <h1>Enlist Your Company</h1>
      <div>
        <strong>
          Valoo's visionary secondary market solution reshapes the business landscape, offering a strategic gateway for enterprises to optimize value and cultivate lasting success.
        </strong>
      </div>
      <div className="separatorEmptyMedium"></div>
      <div>
        In order to add a company to the platform, please schedule a conversation with the company representative in the following link:  
      </div>
      <div className="separatorEmptyLarge"></div>
      <div>
        <a href={url} className="containerFlex linkDarkBlue" target="_blank" rel="noopener noreferrer">
          <CalendarMonth />
          <div className='separatorVerticalEmptySuperSmall'></div>
          <span>Valoo Calendly</span>
        </a>
      </div>
      <Box my={4}>
        <Blog/>
      </Box>
    </>
  );

}

export default CompanySurvey;