import React from 'react';
import HandsIcon from '../../../../../global/icons/HandsIcon';

function TransferBuyHands({ companyName }) {

  return (
    <>
      <div className='separatorEmptyLarge'></div>
      <div className='containerFlex alignItemsCenter'>
        <div>
          <HandsIcon />
        </div>
        <div className='separatorVerticalEmptyVerySmall'></div>
        <div className='fontLarger'>
          <strong>
            Congratulations on joining the investment to purchase shares in {companyName}!
          </strong>
        </div>
      </div>
    </>
  );

}

export default TransferBuyHands;