import React from 'react';
import styles from './BuyAndSellStocksDesktop.module.css'
import WhatDoNowTitle from '../../../../images/titles/what-do-now.svg';
// import VerticalLinearStepper from './VerticalLinearStepper'
import VerticalStepper from './VerticalStepper'
import AboutCompany from './companies/AboutCompany';
import { getStepsArray } from './steps'
import OfferingBuy from './steps/buy/OfferingBuy';
import SigningBuy from './steps/buy/SigningBuy';
import TransferBuy from './steps/buy/TransferBuy';
import TransferBuyHands from './steps/buy/TransferBuyHands';
import FundsBuy from './steps/buy/FundsBuy';
import JoinedBuy from './steps/buy/JoinedBuy';
import CompletionBuy from './steps/buy/CompletionBuy';
import CompletionBuyCheers from './steps/buy/CompletionBuyCheers';
import OfferingSell from './steps/sell/OfferingSell';
import ApprovingSell from './steps/sell/ApprovingSell';
import SigningSell from './steps/sell/SigningSell';
import PendingSell from './steps/sell/PendingSell';
import TransactionSell from './steps/sell/TransactionSell';
import CompletionSell from './steps/sell/CompletionSell';

function BuyAndSellStocksDesktop({ isBuyMode, currentCompany, activeStep, setActiveStep }) {

  const steps = getStepsArray(isBuyMode);

  // Buy
  const renderBuyStepsComponent = () => {
    switch (activeStep) {
      case 0:
        return <OfferingBuy
                  currentCompany={currentCompany}
                  setActiveStep={setActiveStep} />;
      case 1:
        return <SigningBuy setActiveStep={setActiveStep} currentCompany={currentCompany}/>;
      case 2:
        return <TransferBuy
                  currentCompany={currentCompany}
                  setActiveStep={setActiveStep} />;
      case 3:
        return <FundsBuy
                  companyId={currentCompany.id} />;
      case 4:
        return <JoinedBuy 
                  companyName={currentCompany.meta.companyName} 
                  companyId={currentCompany.id} />;
      case 5:
        return <CompletionBuy />;
      default:
        return <div>default</div>;
    }
  };

  // Sell
  const renderSellStepsComponent = () => {
    switch (activeStep) {
      case 0:
        return <OfferingSell 
                  currentCompany={currentCompany}
                  setActiveStep={setActiveStep} />;
      case 1:
        return <ApprovingSell />;
      case 2:
        return <SigningSell 
                  companyId={currentCompany.id} 
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  />;
      case 3:
        return <PendingSell 
                  companyId={currentCompany.id} />;
      case 4:
        return <TransactionSell />;
      case 5:
        return <CompletionSell />;
      default:
        return <div>default</div>;
    }
  };

  

  return (
    <>
      {/* Render the common form elements */}
      <div className={`firstBackground ${styles.card}`}>

        <div className={styles.inCardContainer}>
          <div>
            <AboutCompany 
              currentCompany={currentCompany} 
              isBuyMode={isBuyMode}
            />
            <div className='separatorEmptyLarge'></div>
            <div className='separatorEmptySmall'></div>
            <VerticalStepper isBuyMode={isBuyMode} steps={steps} activeStep={activeStep} />
            {/* <VerticalLinearStepper isBuyMode={isBuyMode} /> */}
          </div>
          <div className='separatorVerticalEmptySmall'></div>
          <div className='separatorVerticalGray'></div>
          <div className='separatorVerticalEmptySmall'></div>
          <div>
            {(activeStep === 2 && isBuyMode) && <TransferBuyHands companyName={currentCompany.meta.companyName} />}
            {(activeStep === 5 && isBuyMode) && <CompletionBuyCheers />}
            {activeStep !== 5 && (
              <h3>
                <img className='titleImage' src={WhatDoNowTitle} alt='What Do I Need To Do Now?' />
              </h3>
            )}
            {isBuyMode ? 
              renderBuyStepsComponent()
              :
              renderSellStepsComponent()
            }
          </div>
        </div>

      </div> {/* card end */}
    </>
  );
}

export default BuyAndSellStocksDesktop;
