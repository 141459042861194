import React from "react";
import styles from "./UserWalletCompany.module.css";
import { formatWithThousandsSeparators } from "../../../../utils/strings";

function UserWalletCompany({ companyData }) {
  const numberOfStocks = companyData?.number_of_stocks;

  const purchasingSteps = companyData?.steps;
  const renderPurchasingStepsText = () => {
    switch (purchasingSteps) {
      case 0:
        return <>Offering</>;
      case 1:
        return <>Joined the partnership</>;
      case 2:
        return <>In purchasing process</>;
      default:
        return <>Offering</>;
    }
  };

  return (
    <div className={styles.container}>
      <div>
        My Stocks
        <div>
          <strong className="orangeFont">
            {/* {formatWithThousandsSeparators(numberOfStocks)} */}
            {purchasingSteps && purchasingSteps <= 4 ? (
              <>in progress</>
            ) : (
              companyData?.progress_text || ""
            )}
          </strong>
          {/* <div className="separatorEmptyMedium"></div>
          <div className={styles.text}>
              <strong className="darkBlueFont" style={{
                whiteSpace:"nowrap",
                width:"150px",
                overflow:"hidden",
                textOverflow:"ellipsis"
              }}>{companyData?.progress_text}</strong>
          </div> */}
        </div>
      </div>
      {/* <div className="separatorEmptyMedium"></div> */}
      {/* <div>
        Purchasing Steps
        <div>
          <strong className="orangeFont">{renderPurchasingStepsText()}</strong>
        </div> */}
        {/* <div className="separatorEmptyMedium"></div> */}
        {/* <div className="companyWalletProgress">
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <strong className="orangeFont">
              {determineProgressValue()}%
            </strong>
            <LinearProgress
              variant="determinate"
              value={determineProgressValue()}
              style={{ width: "100%", height: "7px", borderRadius: "24px" }}
            />
          </div>
        </div> */}
      {/* </div> */}
    </div>
  );
}

export default UserWalletCompany;
