import React, { useState } from "react";
import UploadIcon from "../../../../../global/icons/UploadIcon";
import { Button } from "@mui/material";
import DoneIcon from "../../../../../global/icons/DoneIcon";
import { useAuth } from "../../../../../../hooks/useAuth";
import {
  sendLeadToZapier,
  buildLeadData,
  leadTypeEnum,
} from "../../../../../../utils/zapier";
import { apiInstance } from "../../../../../../services/api.service";

function PendingSellTax({ companyId }) {
  //== FileUpload - START ==
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      setUploading(true);

      const formData = new FormData();

      formData.append("companyId", companyId);
      formData.append("file", selectedFile);
      formData.append("fileName", selectedFile.name);
      try {
        const response = await apiInstance.patch("/api/docs/user/upload/file", formData);

        if (!response.status >= 200 && response.status < 300) {
          throw new Error("File upload failed");
        }

        handleZapier(selectedFile.name);

        setUploaded(true);
      } catch (error) {
        console.error("Error uploading file:", error);
        // Handle upload error
      } finally {
        setUploading(false);
      }
    }
  };

  const handleAnotherFileClick = () => {
    setSelectedFile(null);
    setUploaded(false);
    setUploading(false);
  };
  //== FileUpload - END ==

  //== Zapier - START ==
  const { user } = useAuth();

  const handleZapier = async (fileName) => {
    const leadContent = {
      userId: user.id,
      companyId: companyId,
      dashboardType: "sell",
      step: 3,
      fileName: fileName,
      phone: user?.phone || "",
    };
    const leadData = buildLeadData(leadTypeEnum.StocksSell, leadContent);
    await sendLeadToZapier(leadData);
  };
  //== Zapier - END ==

  return (
    <>
      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className="dragBorder textAlignCenter"
      >
        <input
          type="file"
          id="fileInputTax"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        {!selectedFile ? (
          <label htmlFor="fileInputTax" style={{ cursor: "pointer" }}>
            <UploadIcon />
            <div>
              Drag <strong>Withholding tax certificate</strong> to upload
            </div>
            <div className="fontGray">OR</div>
            <div className="orangeFont fontUnderline">Choose file</div>
          </label>
        ) : (
          <>
            <div className="containerFlex flexCenterHorizontally">
              <div>{uploaded ? <DoneIcon /> : <UploadIcon />}</div>
              <div className="separatorVerticalEmptySuperSmall"></div>
              <strong className="fontGray fontUnderline">
                {selectedFile.name}
              </strong>
            </div>
          </>
        )}
      </div>

      <div className="separatorEmptyMedium"></div>

      {!uploaded ? (
        <div className="containerFlex flexEnd">
          <Button
            variant="contained"
            color="orange"
            sx={{ textTransform: "none" }}
            style={{ borderRadius: 20 }}
            onClick={handleFileUpload}
            disabled={uploading || !selectedFile}
          >
            {uploading ? "Loading..." : "Continue"}
          </Button>
        </div>
      ) : (
        <div
          className="containerFlex flexEnd linkDarkBlue"
          style={{ cursor: "pointer" }}
          onClick={handleAnotherFileClick}
        >
          <div className="fontUnderline">Upload Another File</div>
        </div>
      )}
    </>
  );
}

export default PendingSellTax;
