import React, { useEffect, useState } from 'react';
import ArticleCarousel from './ArticleCarousel';
import { apiInstance } from '../../services/api.service';

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [imagePaths, setImagePaths] = useState({});

  useEffect(() => {
    // Fetch articles from the WordPress API
    apiInstance
      .get("/api/stay-updated")
      .then((response) => {
        setArticles(response.data?.stayUpdatedData);

        // Extract image paths from each article and store in an object
        const images = response?.data?.stayUpdatedData.reduce((acc, article) => {
          if (article.featured_image) {
            acc[article.id] = article.featured_image; // Use article.article_id
          }
          return acc; // Ensure the accumulator is returned
        }, {});

        setImagePaths(images);
      })
      .catch((error) => {
        console.error('Error fetching articles:', error);
      });
  }, []);

  return (
    <>
      <ArticleCarousel articles={articles} imagePaths={imagePaths} />
      <div className='separatorEmptyLarge'></div>
    </>
  );
};

export default ArticleList;
