import React from 'react';
import styles from './Tips.module.css'
import TipImage1 from '../../../../../images/stocks/tips-1.svg'
import TipImage2 from '../../../../../images/stocks/tips-2.svg'
import Typography from '@mui/material/Typography'

export default function Tips({isBuyMode, activeStep}) {

  let whatIsTheNextStep;
  let ourTip;
  switch (activeStep) {
    case 0:
      if (isBuyMode) {
        // Buy | Offering
        whatIsTheNextStep = 'After you have chosen the number of shares and set your desired price, we will check the availability of the deal. If you\'re uncertain about the ideal share price for your needs, feel free to reach out to your portfolio manager through the button on the top bar. They can provide additional transaction details and guidance.';
        ourTip = '“It\'s far better to buy a wonderful company at a fair price than a fair company at a wonderful price.” - Warren Buffett. Consider the quality of the investment rather than solely focusing on the immediate price. Investing in a solid, well-managed company can pay off in the long run.';
      } else {
        // Sell | Offering
        whatIsTheNextStep = 'After you have chosen the number of shares and set your desired price, we will check the deal. Once the deal is approved, we will send you the transaction documents. If you\'re uncertain about the ideal share price for you, feel free to reach out to your portfolio manager through the button on the top bar. They can provide additional transaction details and guidance.';
        ourTip = '“It\'s far better to buy a wonderful company at a fair price than a fair company at a wonderful price.” - Warren Buffett. Consider the quality of the investment rather than solely focusing on the immediate price. Investing in a solid, well-managed company can pay off in the long run.';
      }
      break;
    case 1:
      if (isBuyMode) {
        // Buy | Signing
        whatIsTheNextStep = 'If the deal is available, you will receive a subscription agreement for investing in the company according to the number of shares you have chosen to purchase.';
        ourTip = '“All there is to investing is picking good stocks at good times and staying with them as long as they remain good companies.” - Warren Buffett. Successful investing is about making informed choices and having the patience and conviction to stay committed to those choices, even during market fluctuations.';
      } else {
        // Sell | Approving
        whatIsTheNextStep = 'If the deal is approved, you will receive transaction documents according to the number of shares you have chosen to sell.';
        ourTip = '“All there is to investing is picking good stocks at good times and staying with them as long as they remain good companies.” - Warren Buffett. Successful investing is about making informed choices and having the patience and conviction to stay committed to those choices, even during market fluctuations.';
      }
      break;
    case 2:
      if (isBuyMode) {
        // Buy | Transfer of Funds
        whatIsTheNextStep = 'We will confirm the receipt of the consideration and will do proceed with the closing which includes the registration of the shares and registration with the Registrar of Partnerships.';
        ourTip = '“Diversification May Preserve Wealth, But Concentration Builds Wealth.” - Warren Buffett. While diversification is important for risk management, don\'t shy away from focusing on your best investment ideas. Concentrate your funds where you have the most confidence and belief in long-term success.';
      } else {
        // Sell | Signing
        whatIsTheNextStep = 'Once you have signed the transaction documents, we will offer your deal to potential buyers.';
        ourTip = '“The stock market is designed to transfer money from the active to the patient.” - Warren Buffett. Stay patient and focused on your long-term goals, avoiding impulsive decisions influenced by short-term market fluctuations.';
      }
      break;
    case 3:
      if (isBuyMode) {
        // Buy | Funds Confirmation
        whatIsTheNextStep = 'We will confirm the receipt of the funds and proceed to complete the closing process, which includes the registration of your shares and registration with the Registrar of the partnerships.';
        ourTip = '“The stock market is designed to transfer money from the active to the patient.” - Warren Buffett. Stay patient and focused on your long-term goals, avoiding impulsive decisions influenced by short-term market fluctuations.';
      } else {
        // Sell | Pending
        whatIsTheNextStep = 'While we are making this deal happen, kindly upload your financial statements.';
        ourTip = '“The stock market is designed to transfer money from the active to the patient.” - Warren Buffett. Stay patient and focused on your long-term goals, avoiding impulsive decisions influenced by short-term market fluctuations.';
      }
      break;
    case 4:
      if (isBuyMode) {
        // Buy | Joined the Partnership
        whatIsTheNextStep = 'Once the transaction is completed, Valoo partnership will be registered as a shareholder, and both the company and you will receive a partnership certificate along with the confirmation of the shares\' registration.';
        ourTip = '“Investing is laying out money now to get more money back in the future.” - Warren Buffett. Recognize that investing is a forward-looking endeavor. Embrace the idea that the money you invest today is a seed for future wealth; be patient and nurture your investments over time.';
      } else {
        // Sell | Transaction
        whatIsTheNextStep = 'A deal has been made; we will soon transfer your funds!';
        ourTip = '“Investing is laying out money now to get more money back in the future.” - Warren Buffett. Recognize that investing is a forward-looking endeavor. Embrace the idea that the money you invest today is a seed for future wealth; be patient and nurture your investments over time.';
      }
      break;
    case 5:
      if (isBuyMode) {
        // Buy | Completion
        whatIsTheNextStep = 'You will receive periodic updates on both the company you have invested in and the development of your investment. We\'ll keep you in the loop with all the exciting news and progress!';
        ourTip = '“The best investment you can make is in yourself.” - Warren Buffett. Take the time to reflect, learn from your experiences, and continue to invest in your knowledge and skills. A well-informed investor is a successful investor.';
      } else {
        // Sell | Completion
        whatIsTheNextStep = 'The Funds Have Been Transferred, The Deal is Done!';
        ourTip = 'Enjoy spending your newly acquired funds 😊 “The best investment you can make is in yourself.” - Warren Buffett. Now is the time to reignite your investments in companies with Valoo. Reach out to learn about exclusive investment opportunities waiting now just for you. Alternatively, explore the buyer\'s section within the system for access to unique investment opportunities!';
      }
      break;
    default:
      whatIsTheNextStep = '';
      ourTip = '';
      break;
  }

  return(
    <div className="maxWidth marginAuto">
      <div 
        className={`firstBackground ${styles.card} ${styles.container}`}
        style={{display: 'flex'}}
      >
        <div>
          <div className='separatorEmptyMedium'></div>
          <div>
            <img src={TipImage1} alt='What Next' />
          </div>
          <div className='separatorEmptyMedium'></div>
          <strong>What Is The Next Step?</strong>
          <Typography>
            {whatIsTheNextStep}
          </Typography>
        </div>
        <div>
          <div className='separatorEmptyMedium'></div>
          <div>
            <img src={TipImage2} alt='Our Tip' />
          </div>
          <div className='separatorEmptyMedium'></div>
          <strong>Our Tip</strong>
          <Typography>
            {ourTip}
          </Typography>
        </div>
      </div>
    </div>
  );

}

