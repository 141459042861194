import React, { useState, useRef } from 'react';
import styles from './ArticleCarousel.module.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useIsMobile } from '../../hooks/useIsMobile'

function ArticleCarousel(props) {
  
  // == WordPress API - START ==
  const { articles, imagePaths } = props;

  function trimTitle(title, maxLength) {
    if (title?.length <= maxLength) {
      return fixSpecialChars(title);
    } else {
      title = title?.slice(0, maxLength - 3) + '...';
      return fixSpecialChars(title);
    }
  }
  
  function fixSpecialChars(str) {
    return str.replaceAll('&#8217;', '\'');
  }
  // == WordPress API - END ==

  // == Scroll - START ==
  const [scrollPosition, setScrollPosition] = useState(0);
  const carouselRef = useRef(null);

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft -= 200;
      setScrollPosition(carouselRef.current.scrollLeft);
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft += 200;
      setScrollPosition(carouselRef.current.scrollLeft);
    }
  };
  // == Scroll - END ==

  // == Is Mobile - START ==
  const isMobile = useIsMobile();
  // == Is Mobile - END ==


  return(
    <div className={styles.carousel}>

      {!isMobile && 
        <div className={styles.buttons}>
          <button className={styles.buttonLeft} onClick={scrollLeft}><ArrowBackIosIcon /></button>
          <button className={styles.buttonRight} onClick={scrollRight}><ArrowForwardIosIcon /></button>
        </div>
      }

      <div class={styles.mask} ref={carouselRef}>

        {articles.map((article) => (
            <div key={article.id} className={styles.articleContainer}>
              <a href={article.link} target="_blank" rel="noopener noreferrer">
                {imagePaths[article.id] && (
                  <img src={imagePaths[article.id]} alt={article.title} className={styles.articleImage} style={{ width: '200px' }} />
                )}
                <div className={styles.articleTitle}>{trimTitle(article.title, 45)}</div>
              </a>
            </div>
          )
        )}

      </div>



    </div>
  );

}

export default ArticleCarousel;
