import React from 'react';

function UploadIcon() {
  
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_108_3578)">
      <path d="M19.474 13.789C19.6135 13.789 19.7473 13.8444 19.8459 13.9431C19.9446 14.0417 20 14.1755 20 14.315V16.21C20 17.0197 19.6783 17.7962 19.1058 18.3688C18.5332 18.9413 17.7567 19.263 16.947 19.263H7.053C6.24329 19.263 5.46675 18.9413 4.8942 18.3688C4.32165 17.7962 4 17.0197 4 16.21V14.316C4 14.1764 4.05547 14.0424 4.15421 13.9437C4.25295 13.845 4.38686 13.7895 4.5265 13.7895C4.66614 13.7895 4.80005 13.845 4.89879 13.9437C4.99753 14.0424 5.053 14.1764 5.053 14.316V16.21C5.053 16.7404 5.26371 17.2491 5.63879 17.6242C6.01386 17.9993 6.52257 18.21 7.053 18.21H16.947C17.4774 18.21 17.9861 17.9993 18.3612 17.6242C18.7363 17.2491 18.947 16.7404 18.947 16.21V14.316C18.947 14.1765 19.0024 14.0427 19.1011 13.9441C19.1997 13.8454 19.3335 13.79 19.473 13.79L19.474 13.789Z" fill="#011E37"/>
      <path d="M11.7877 14.9395C11.654 14.9395 11.5254 14.8886 11.4279 14.7973C11.3304 14.7059 11.2713 14.5808 11.2627 14.4475L11.2627 5.72945L8.98967 7.85045C8.89231 7.94113 8.76421 7.99154 8.63117 7.99154C8.49812 7.99154 8.37002 7.94113 8.27267 7.85045L8.24567 7.82345C8.15499 7.7261 8.10457 7.598 8.10457 7.46495C8.10457 7.33191 8.15499 7.20381 8.24567 7.10645L8.27267 7.07945L11.4257 4.13645L11.4547 4.11045L11.4627 4.10345L11.4817 4.08945L11.4947 4.08045L11.5087 4.07145L11.5217 4.06345L11.5427 4.05145L11.5507 4.05145L11.5707 4.04245L11.5847 4.03645L11.6027 4.02945L11.6177 4.02445L11.6317 4.02445L11.6507 4.01945C11.6681 4.01483 11.6858 4.01116 11.7037 4.00845L11.7167 4.00845L11.7517 4.00845L11.7877 4.00845C11.8144 4.00843 11.8412 4.01044 11.8677 4.01445L11.8927 4.01445C11.9742 3.99494 12.0592 3.9952 12.1406 4.0152C12.222 4.03521 12.2975 4.07438 12.3607 4.12945L15.5157 7.08045C15.5662 7.1276 15.607 7.18424 15.6357 7.24714C15.6643 7.31005 15.6803 7.37798 15.6828 7.44707C15.6852 7.51616 15.674 7.58504 15.6497 7.64979C15.6255 7.71455 15.5888 7.77389 15.5417 7.82445C15.4945 7.87501 15.4379 7.91579 15.375 7.94446C15.3121 7.97313 15.2441 7.98913 15.1751 7.99154C15.106 7.99396 15.0371 7.98274 14.9723 7.95853C14.9076 7.93432 14.8482 7.8976 14.7977 7.85045L12.3137 5.52945L12.3137 14.4095C12.3137 14.549 12.2582 14.6827 12.1596 14.7814C12.061 14.88 11.9272 14.9355 11.7877 14.9355L11.7877 14.9395Z" fill="#011E37"/>
      </g>
      <defs>
      <clipPath id="clip0_108_3578">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}

export default UploadIcon;