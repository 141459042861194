import React from "react";
import { Typography } from '@mui/material';

export default function Copyright() {

  const currentYear = new Date().getFullYear();

  return(
    <Typography variant="caption">
      Copyright © {currentYear} Valoo
    </Typography>
  );

}