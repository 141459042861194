import React from 'react';
import { IconButton } from '@mui/material';
import { Mail, WhatsApp, CalendarMonth } from '@mui/icons-material';

function SocialIcons() {
  return (
    <div>
      {/* <IconButton href="#" color="white">
        <Phone fontSize="small" />
      </IconButton> */}
      <IconButton href="https://api.whatsapp.com/send?phone=972547315570" target="_blank" rel="noopener noreferrer" 
                  color="white"
                  className='whiteIcon'>
        <WhatsApp fontSize="small" />
      </IconButton>
      <IconButton href="mailto:info@valoo.io" target="_blank" rel="noopener noreferrer" 
                  color="white"
                  className='whiteIcon'>
        <Mail fontSize="small" />
      </IconButton>
      <IconButton href="https://calendly.com/hadar-9s8/30min?embed_domain=valoo.io&embed_type=Inline&text_color=011e37&primary_color=011E37" target="_blank" rel="noopener noreferrer"
                  color="white"
                  className='whiteIcon'>
        <CalendarMonth fontSize="small" />
      </IconButton>
    </div>
  );
}

export default SocialIcons;
