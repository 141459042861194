import axios from "axios";

let baseURL = "http://localhost:4000/v1";
if (process.env.NODE_ENV === "production") {
  baseURL = process.env.REACT_APP_API_ENDPOINT;
}
const apiInstance = axios.create({
  baseURL,
});

apiInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle any request errors here
    return Promise.reject(error);
  }
);

export { apiInstance };
