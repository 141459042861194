import React, { useEffect, useState, useContext } from "react";
import styles from "./Wallet.module.css";
import WalletTitleImage from "../../../../images/titles/my-wallet.svg";
import CompanyDetails from "./CompanyDetails";
import CompaniesWallet from "../../../layout/main/wallet/CompaniesWallet";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { CompanyContext } from '../../../state/CompanyContext';
import { useAuth } from "../../../../hooks/useAuth";
import { apiInstance } from "../../../../services/api.service";

function Wallet() {
  const { logout } = useAuth();

  const isMobile = useIsMobile();

  const { state } = useContext(CompanyContext);
  const { currentCompanyIdWallet } = state

  // Fetch user wallet data from the API
  const [companyIds, setCompanyIds] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [userWalletData, setUserWalletData] = useState([]);

  const [companyDataUpdated, setCompanyDataUpdated] = useState(true);

  useEffect(() => {

    let intervalId;
    const fetchUserWalletData = async () => {
      try {
        const response = await apiInstance.get('/api/user-wallet/user-wallet-buy');
        const data = response.data
    
        setUserWalletData(data.userWalletData);
        const newCompanyIds = data.userWalletData.map((data) => data.company_id);
        setCompanyIds(newCompanyIds);
    
        const userWalletCompanyData = data.userWalletData.find((data) => data.company_id === currentCompanyIdWallet);
        setCompanyData(userWalletCompanyData);
    
        // Check if userWalletData is empty (session end in the server)
        if (data.userWalletData.length === 0) {
          if(intervalId){
            clearInterval(intervalId);
          }
          // logout();
        }
      } catch (error) {
        if(intervalId) clearInterval(intervalId);
        console.error('Error fetching user wallet data:', error);
      }
    };

    // Fetch user wallet data initially
    fetchUserWalletData();

    // Set up interval for automatic updates
    intervalId = setInterval(() => {
      fetchUserWalletData();
    }, 2000); // (2 seconds)

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);

  }, [currentCompanyIdWallet]);

  return(
    <div className='secondBackground' id='myWallet'>

      <div className="maxWidth marginAuto">
        <img className="titleImage" src={WalletTitleImage} alt="Stay Updated" />
      </div>

      <div className={`maxWidth marginAuto ${styles.container}`}>
        {isMobile ? (
            // Mobile version
            <div>
              <div>
                <CompaniesWallet 
                  type='wallet' 
                  companyIds={companyIds} 
                  userWalletData={userWalletData}
                />
              </div>
              <CompanyDetails />
            </div>
          ) : (
            // Desktop version
            <>
              <div className='separatorVerticalEmpty'></div>
              <div>
                <CompaniesWallet 
                  type='wallet' 
                  companyIds={companyIds} 
                  userWalletData={userWalletData}
                />
              </div>
              <div className='separatorVerticalEmpty'></div>
              <CompanyDetails />
            </>
          )}
        </div>



    </div>
  );

}

export default Wallet;
