import React from 'react';
import styles from './NewsletterCarousel.module.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useIsMobile } from '../../../../hooks/useIsMobile'

function NewsletterCarousel(props) {
  
  // == WordPress API - START ==
  const { articles } = props;

  function trimTitle(title, maxLength) {
    if (title.length <= maxLength) {
      return fixSpecialChars(title);
    } else {
      title = title.slice(0, maxLength - 3) + '...';
      return fixSpecialChars(title);
    }
  }
  
  function fixSpecialChars(str) {
    return str.replaceAll('&#8217;', '\'');
  }
  // == WordPress API - END ==

  // == Scroll - START ==
  const scrollLeft = () => {
    const carousel = document.querySelector('.'+ styles.mask);
    carousel.scrollLeft -= 200; // Adjust the scroll distance as needed
  };

  const scrollRight = () => {
    const carousel = document.querySelector('.'+ styles.mask);
    carousel.scrollLeft += 200; // Adjust the scroll distance as needed
  };
  // == Scroll - END ==

  // == Is Mobile - START ==
  const isMobile = useIsMobile();
  // == Is Mobile - END ==


  return(
    <div className={styles.carousel}>

      {!isMobile && 
        <div className={styles.buttons}>
          <button className={styles.buttonLeft} onClick={scrollLeft}><ArrowBackIosIcon /></button>
          <button className={styles.buttonRight} onClick={scrollRight}><ArrowForwardIosIcon /></button>
        </div>
      }

      <div class={styles.mask}>

        {articles.map((article) => (
            <div key={article?.id} className={styles.articleContainer}>
              <a href={article.link} target="_blank" rel="noopener noreferrer">
                {article?.image && (
                  <img src={article?.image} alt={article?.image} className={styles.articleImage} style={{ width: '200px' }} />
                )}
                <div className={styles.articleTitle}>{trimTitle(article.headline, 50)}</div>
              </a>
            </div>
          )
        )}

      </div>



    </div>
  );

}

export default NewsletterCarousel;
