import React from 'react';
import LoadingDots from '../../../../../global/LoadingDots';
import { Button } from '@mui/material';
import { apiInstance } from '../../../../../../services/api.service';

function SigningBuy({ setActiveStep , currentCompany }) {


  const handleCancelOffer = async () => {
    try {
      const response = await apiInstance.post(
        `/api/user-wallet/user-wallet-buy/update-wallet`, 
        {
          company_id: currentCompany?.id,
          new_steps: 0
        }
      );
      if (response.data.success) {
        setActiveStep(0);
        console.log('Steps Updated Successfully',response.data)
      }
    } catch (error) {
      console.error('Error updating steps:', error);
    }
  };


  return (
    <>
      <div>
        We are checking the availability of the deal, and will send deal documents soon.
      </div>
      <div className='separatorEmptyLarge'></div>
      
      <div className='containerFlex'>
        <strong>
          A representative is checking the availability of the deal and will contact you soon!
        </strong>
        <div className='separatorVerticalEmptyVerySmall'></div>
        <LoadingDots />
      </div>

      <div className='separatorEmptyLarge'></div>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <div>
        <svg width="96" height="97" viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M95.2209 73.0811L88.9789 64.3113L91.2147 62.7201C94.529 60.3609 93.5617 55.2072 89.6246 54.2049L80.9421 51.9938L81.6965 43.066C81.8549 41.1919 80.9385 39.449 79.3048 38.5171C75.7314 36.4796 73.0563 39.8229 71.936 40.366C66.1338 32.7872 66.0387 30.6716 63.1792 30.1901C60.3215 29.7094 59.2602 31.8523 52.03 36.611L64.7412 54.4702H17.4194V89.0094C17.4194 92.8804 20.5686 96.0296 24.4396 96.0296H69.3687C73.2397 96.0296 76.3889 92.8804 76.3889 89.0094V70.8348L86.2246 84.6539L94.2317 78.955C96.1242 77.6085 96.5678 74.9734 95.2209 73.0811ZM90.3789 58.47C90.7272 60.3355 88.7389 60.8068 87.3508 62.0235C86.6267 61.0062 83.293 56.322 82.5696 55.3056C87.8211 56.9442 89.996 56.4199 90.3789 58.47ZM77.9137 40.9566C79.7226 41.9881 78.52 43.7972 78.3457 49.3714L73.5645 42.6538C75.1689 41.7408 76.2648 40.0166 77.9137 40.9566ZM55.9463 37.2707L61.6658 33.2C62.2967 32.7512 63.1749 32.8989 63.6238 33.5296L69.6486 41.9945L75.2389 49.849L68.3756 54.7339L55.9463 37.2707ZM41.6621 93.2217H24.4396C22.117 93.2217 20.2275 91.332 20.2275 89.0096V57.2784H41.6621V93.2217ZM49.3374 93.2217H44.4701V57.2784H49.3374V93.2217ZM73.5808 89.0096C73.5808 91.332 71.6913 93.2217 69.3687 93.2217H52.1455V57.2784H66.74L73.5808 66.8897V89.0096ZM72.8264 60.9871L70.0039 57.0216L76.8672 52.1366L79.6897 56.102L72.8264 60.9871ZM92.6036 76.6674L86.8841 80.7381L74.4547 63.2749L81.318 58.39C82.3366 59.8212 78.9981 55.1308 92.9333 74.7092C93.3822 75.3403 93.2343 76.2185 92.6036 76.6674Z" fill="#FF7800"/>
          <path d="M18.8234 29.8725L30.4571 35.9885L28.2351 23.0345L37.6468 13.8604L24.6402 11.9702L18.8234 0.184326L13.0068 11.9702L0 13.8602L9.41171 23.0343L7.18997 35.9885L18.8234 29.8725ZM6.03435 15.8208L14.8713 14.5369L18.8234 6.52925L22.7755 14.5369L31.6125 15.8208L25.218 22.0541L26.7276 30.8554L18.8234 26.7L10.9195 30.8554L12.4291 22.0541L6.03435 15.8208Z" fill="#FF7800"/>
          <path d="M38.3826 46.2325L42.5112 52.0707L44.6338 45.2424L51.4621 43.1201L45.6239 38.9913L45.7152 31.8416L39.9847 36.1181L33.2132 33.8216L35.5094 40.5934L31.2329 46.3239L38.3826 46.2325ZM37.6993 38.3083L40.4808 39.2515L42.8345 37.4949L42.7969 40.4316L45.1948 42.1275L42.3903 42.9993L41.5185 45.8036L39.8226 43.4057L36.8859 43.4434L38.6425 41.0895L37.6993 38.3083Z" fill="#FF7800"/>
          <path d="M47.8781 31.6235L54.5147 24.8151L63.8856 26.4224L59.4612 18.0068L63.8856 9.59124L54.5147 11.1986L47.8781 4.39014L46.511 13.799L37.9851 18.0068L46.511 22.2146L47.8781 31.6235ZM49.0775 15.6638L49.8389 10.4245L53.5343 14.2158L58.7525 13.3207L56.2888 18.0068L58.7525 22.6929L53.5343 21.7979L49.8389 25.5892L49.0775 20.3499L44.33 18.0068L49.0775 15.6638Z" fill="#FF7800"/>
          <path d="M11.793 42.2327H14.601V45.0407H11.793V42.2327Z" fill="#FF7800"/>
          <path d="M20.7854 42.2327H23.5935V45.0407H20.7854V42.2327Z" fill="#FF7800"/>
          <path d="M16.2891 37.7363H19.0971V40.5444H16.2891V37.7363Z" fill="#FF7800"/>
          <path d="M16.2891 46.729H19.0971V49.5371H16.2891V46.729Z" fill="#FF7800"/>
          <path d="M69.0847 13.9541H71.8928V16.7622H69.0847V13.9541Z" fill="#FF7800"/>
          <path d="M78.0771 13.9541H80.8852V16.7622H78.0771V13.9541Z" fill="#FF7800"/>
          <path d="M73.5808 9.45801H76.3889V12.2661H73.5808V9.45801Z" fill="#FF7800"/>
          <path d="M73.5808 18.4507H76.3889V21.2588H73.5808V18.4507Z" fill="#FF7800"/>
        </svg>
      </div>
      <div>
          <Button
            onClick={handleCancelOffer}
            fullWidth
            variant="contained"
            sx={{ textTransform: "none" }}
            style={{ borderRadius: 20 }}
            color="orange"
          >
            Cancel Offer
          </Button>
      </div>
    </div>
    </>
  );

}

export default SigningBuy;