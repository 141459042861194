import React, { useContext, useState, useEffect } from 'react';
import styles from './Companies.module.css';
import { CompanyContext } from '../../../../state/CompanyContext'
import { useIsMobile } from '../../../../../hooks/useIsMobile'
import Button from '@mui/material/Button';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Box from '@mui/material/Box';
import AddDialog from '../../../../dialogs/add-company/AddDialog';
import PublicAssetsEnum from '../../../../../constants/PublicAssetsEnum';
import { apiInstance } from '../../../../../services/api.service';

function Companies({ type, setCompanyDataUpdated, isBuyMode }) {

  // == state - START ==
  const { state, dispatch } = useContext(CompanyContext);

  const handleCompanyClick = (companyId) => {
    // Dispatch an action based on the 'type' prop
    if (type === 'stocks') {
      setCompanyDataUpdated(true);
      if (isBuyMode) {
        dispatch({ type: "UPDATE_CURRENT_COMPANY_STOCKS", companyId: companyId });
      } else {
        dispatch({
          type: "UPDATE_CURRENT_COMPANY_SELL_STOCKS",
          companyId: companyId,
        });
      }
    } else if (type === 'wallet') {
      dispatch({ type: 'UPDATE_CURRENT_COMPANY_WALLET', companyId });
      setCompanyDataUpdated(true);
    }
  };
  const { currentCompanyIdStocks, currentCompanyIdWallet, currentSellCompanyIdStocks, companiesData } = state;
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiInstance.get('/api/company/all');
        const data = response.data
        dispatch({ type: 'SET_COMPANIES_DATA', data });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dispatch]);
  // == state - END ==

  // == AddDialog - START ==
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  // == AddDialog - END ==

  const isMobile = useIsMobile();

  // Filter the companiesData based on the provided companyIds
  let filteredCompaniesData = [];
  if (companiesData?.length > 0) {
    const companiesArray = companiesData;
    if (companiesArray && companiesArray.length > 0) {
      if (isBuyMode) {
        filteredCompaniesData = companiesArray
        .sort((a, b) => a?.buy_rating - b?.buy_rating)
        .filter((company) => {
          return checkListingPreference(company);
        });
      } else {
        filteredCompaniesData = companiesArray
        .sort((a, b) => a?.sell_rating - b?.sell_rating)
        .filter((company) => {
          return checkListingPreference(company);
        });
      }
      
    } else {
      console.warn('Warning: companiesData is empty.');
    }
  }

  function checkListingPreference(company) {
    const { listingPreference } = company;
  
    // Check conditions for displaying the company
    if (listingPreference === 'all') {
      return true; // Display for 'all'
    } else if (listingPreference === 'nothing') {
      return false; // Do not display for 'nothing'
    } else if (listingPreference === 'buy') {
      return isBuyMode; // Display for 'buy' if isBuyMode is true
    } else if (listingPreference === 'sell') {
      return !isBuyMode; // Display for 'sell' if isBuyMode is false
    }
  
    return false; // Default: Do not display if none of the conditions match
  }

  // Set Default Company
  const onDefaultCompany = () => {
    const companyId = filteredCompaniesData?.[0]?.id;
    const buyStockId = currentCompanyIdStocks || companyId 
    const sellStockId = currentSellCompanyIdStocks || companyId
    if (isBuyMode) {
      dispatch({ type: "UPDATE_CURRENT_COMPANY_STOCKS", companyId: buyStockId});
    } else {
      dispatch({
        type: "UPDATE_CURRENT_COMPANY_SELL_STOCKS",
        companyId: sellStockId,
      });
    }
  };
  useEffect(() => {
    if (companiesData?.length > 0 && type === "stocks") {
      onDefaultCompany();
    }
  }, [companiesData, isBuyMode]);
  // Set Default Company : END
  const companyId = isBuyMode ? currentCompanyIdStocks : currentSellCompanyIdStocks
  return (
    <>
      {isMobile ? (
        // Mobile version
        <div className={styles.carousel}>
          <div class={styles.mask}>
            <div class={styles.slider}>
              {filteredCompaniesData.map((company) => (
                <>
                  <div className='separatorVerticalEmptySmall'></div>
                  <div
                    key={company.id}
                    className={`${styles.companyCard} ${
                      (type === 'stocks' && company.id === companyId) ||
                      (type === 'wallet' && company.id === currentCompanyIdWallet)
                        ? styles.companyCardActive
                        : ''
                    }`}
                    onClick={() => handleCompanyClick(company.id)}
                  >
                    <img src={PublicAssetsEnum.Url + 'public/images/companies/rectangle/' + company.id + '.png'} alt={company.companyName} />
                  </div>
                </>
              ))}

              <div className='separatorVerticalEmptySmall'></div>
              {type === 'stocks' && (
                <div style={{display: "flex", justifyContent: "center"}}>
                  <Button
                    onClick={handleClickOpen}
                    variant="outlined"
                    sx={{ textTransform: 'none', borderStyle: 'dashed', background: 'white', borderRadius: 4 }}
                  >
                    <Box textAlign="center" style={{padding: "10px 15px"}}>
                      <strong>Can't Find The One You Looking For?</strong>
                      <div className='separatorEmptySmall'></div>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div>
                          <ControlPointIcon fontSize="small" style={{ paddingTop: '6px', paddingRight: '8px' }} /> 
                        </div>
                        <div className='fontSmall' style={{textDecoration: "underline"}}>
                          Add A New Company
                        </div>
                      </div>
                    </Box>
                  </Button>
                  <AddDialog open={open} onClose={handleClose} />
                </div>
              )}
              <div className='separatorVerticalEmpty'></div>

            </div>
          </div>
          <div className='separatorEmptyMedium'></div>
        </div>
      ) : (
        // Desktop version
        <div className={styles.companyContainer}>
          {filteredCompaniesData.map((company) => (
            <React.Fragment key={company.id}>
              <div
                className={`${styles.companyCard} ${
                  (type === 'stocks' && company.id === companyId) ||
                  (type === 'wallet' && company.id === currentCompanyIdWallet)
                    ? styles.companyCardActive
                    : ''
                }`}
                onClick={() => handleCompanyClick(company.id)}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <img src={PublicAssetsEnum.Url + 'public/images/companies/rectangle/' + company.id + '.png'} alt={company.companyName} />
              </div>
              <div className='separatorEmptyMedium'></div>

            </React.Fragment>
          ))}

          {type === 'stocks' && (
            <div style={{display: "flex", justifyContent: "center"}}>
              <Button
                onClick={handleClickOpen}
                variant="outlined"
                sx={{ textTransform: 'none', borderStyle: 'dashed', background: 'white', borderRadius: 4 }}
              >
                <Box textAlign="center" style={{padding: "10px 15px"}}>
                  <strong>Can't Find The One You Looking For?</strong>
                  <div className='separatorEmptySmall'></div>
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div>
                      <ControlPointIcon fontSize="small" style={{ paddingTop: '6px', paddingRight: '8px' }} /> 
                    </div>
                    <div className='fontSmall' style={{textDecoration: "underline"}}>
                      Add A New Company
                    </div>
                  </div>
                </Box>
              </Button>
              <AddDialog open={open} onClose={handleClose} />
            </div>
          )}

        </div>
      )}
    </>
  );
}

export default Companies;
