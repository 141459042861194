import React  , { useState } from 'react';
import styles from './Assistant.module.css';
// import AssistantImage from '../../../../images/header/assistant/assistant.jpg';
import Actions from './actions/Actions'
import { useAuth } from '../../../../hooks/useAuth';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

function Assistant() {

  const { user } = useAuth();

  // == Is Mobile - START ==
  const isMobile = useIsMobile();
  // == Is Mobile - END ==

  // == Assistant Expanded - START ==
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  // == Assistant Expanded - END ==

  return (
    <div className={styles.container}>
      {isMobile ? (
        // Mobile version
        <>
          <div className={styles.containerMobile}>
            <div onClick={handleExpand} style={{paddingRight: '5px'}}>
            <SupportAgentIcon fontSize="small" />
              {/* <img
                src={AssistantImage}
                className={styles.assistantImage}
                alt="Assistant"
              /> */}
            </div>
            <div onClick={handleExpand} className='fontSizeCaption'>
              <div>
                I'm here for 
              </div>
              <div>
                any questions.
              </div>
            </div>
            <IconButton onClick={handleExpand} color='white' style={{padding: '1px'}}>
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <Collapse in={isExpanded} style={{textAlign: 'center'}}>
            <Actions />
          </Collapse>
        </>
      ) : (
        // Desktop version
        <div className={styles.containerDesktop}>
          <div>
            <SupportAgentIcon sx={{ fontSize: 40 }} />
            {/* <img
              src={AssistantImage}
              className={styles.assistantImage}
              alt="Assistant"
            /> */}
          </div>
          <div className={styles.desktopText}>
            <div className={styles.assistantBold}>
              Hi <span className='capitalizeFirstLetter'>{user.fullname}</span>, Good to see you!
            </div>
            <div className={styles.assistantText}>
              I'm here for any questions.
            </div>
          </div>
          <div>
            <Actions />
          </div>
        </div>
      )}
    </div>
  );
}

export default Assistant;
