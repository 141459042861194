import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";

import { CompanyProvider } from "./components/state/CompanyContext";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/theme/theme";
import Auth0ProviderWithNavigate from "./components/providers/AuthProviderWithNavigate";
import { AuthProvider } from "./hooks/useAuth";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <AuthProvider>
        <CompanyProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
        </CompanyProvider>
      </AuthProvider>
    </Auth0ProviderWithNavigate>
  </BrowserRouter>
);

reportWebVitals();
