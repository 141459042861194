import React, { useEffect, useState } from "react";
import NewsletterCarousel from "./NewsletterCarousel";
import { apiInstance } from "../../../../services/api.service";

const NewsletterList = () => {
  const [articles, setArticles] = useState([]);
 

  useEffect(() => {
    // Fetch articles from the WordPress API
    const apiSlug = "/api/newsletter";
    apiInstance.get(apiSlug)
      .then((data) => {
        setArticles(data?.data?.newsletters);
      })
      .catch((error) => {
        console.error("Error fetching newsletter data:", error);
      });
  }, []);

  return (
    <>
      <NewsletterCarousel articles={articles} />
      <div className='separatorEmptyLarge'></div>
    </>
  );
};

export default NewsletterList;
