import { useState, useEffect } from 'react';

/**
A custom hook to determine if the current screen size is considered "mobile" based on the provided maxWidth.
@param {number} maxWidth - The maximum width to be considered "mobile". Default value is 700.
@returns {boolean} - True if the screen size is less than or equal to the maxWidth, otherwise false.
*/
export function useIsMobile(maxWidth = 700) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= maxWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= maxWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [maxWidth]);

  return isMobile;
}
