import React, { useContext, useEffect, useState } from "react";
import styles from "./DealDocumentation.module.css";
import DealCard from "./DealCard";
import { CompanyContext } from "../../../state/CompanyContext";
import { apiInstance } from "../../../../services/api.service";

function DealDocumentation() {
  const {
    state: { currentCompanyIdWallet },
  } = useContext(CompanyContext);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiInstance.get(
            `/api/docs/deal-documents/check?companyId=${currentCompanyIdWallet}`
        );
        setFiles(response?.data);
      } catch (error) {
        console.error("Error fetching deal documents:", error);
      }
    };

    fetchData();
  }, [currentCompanyIdWallet]);

  return (
    <div className={styles.parentContainer}>
      {files?.length > 0 && <strong>Deal Documentation</strong>}
      <div className={styles.container}>
        {files
          .filter((file) => file !== null && file !== undefined)
          .map((file, index) => {
            const url = `/api/docs/deal/files?companyId=${currentCompanyIdWallet}&fileName=${file?.fileName}`;
            return (
              <div
                key={index}
                className={`${styles.box} ${styles.card}`}
              >
                <DealCard title={file?.fileName} url={url} />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default DealDocumentation;