import React from 'react';
import styles from './Bar.module.css'
import { ReactComponent as Logo } from '../../../../images/valoo-logo.svg';
import SocialIcons from './social/SocialIcons';
import FooterLinks from './menu/FooterLinks';

function Bar() {

  return (
    <div className={`${styles.container} maxWidth marginAuto`}>
      <div>
        <Logo alt="Valoo Logo" />
      </div>
      
      <div>
        <FooterLinks />
      </div>

      <div>
        <SocialIcons />
      </div>
    </div>
  );

}

export default Bar;