import React from "react";
import StayUpdatedTitleImage from "../../../../images/titles/stay-updated.svg"
import ArticleList from "../../../articles/ArticleList"

function Blog() {

  return(
    <div className='secondBackground'>
      <div className="maxWidth marginAuto">

        <img className="titleImage" src={StayUpdatedTitleImage} alt="Stay Updated" />
        <div>
          <ArticleList />
        </div>

      </div>
    </div>
  );

}

export default Blog;
