import React from 'react';
import styles from './TabContentB.module.css'

function TabContentB({ content }) {
  return (
    <div className={styles.container}>
      {content.map((item, index) => (
        <React.Fragment key={index}>
          <div className={`${styles.box} ${styles.card}`}>
            <div>
              <img src={item.image} className={styles.topImage} alt={`${index}`} onError={(e) => { e.target.style.display = 'none'; }}/>
            </div>
            <div className='separatorEmptyMedium'></div>
            <strong>{item?.title}</strong>
            <div className='separatorEmptyMedium'></div>
            <div className={styles.contentText}>{item?.content}</div>
          </div>
          {index < content.length - 1 && <div className='separatorEmptyLarge'></div>}
        </React.Fragment>
      ))}
    </div>
  );
}

export default TabContentB;
