import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

const investmentPreferencesOptions = ['AI', 'Fintech', 'Cyber', 'Adtech', 'Agrotech' ,'Other'];

export default function InvestmentPreferencesChips({ selectedPreferences: selectedPreferencesStr, onSelectPreference, setErrorMessage }) {
  const selectedPreferences = React.useMemo(() => {
    return selectedPreferencesStr ? selectedPreferencesStr.split(',') : [];
  },[selectedPreferencesStr])
  const handleChipClick = (preference) => {
    // Toggle the selection of the clicked preference
    const updatedPreferences = selectedPreferences.includes(preference)
      ? selectedPreferences.filter((selected) => selected !== preference)
      : [...selectedPreferences, preference];

    // Update the selected preferences state
    onSelectPreference(updatedPreferences?.join(','));
    setErrorMessage("")
  };

  return (
    <Box sx={{ width: 300, margin: 'auto', textAlign: 'center' }}>
      <Typography id="investment-preferences-label" gutterBottom>
        Investment Preferences
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
        {investmentPreferencesOptions.map((preference) => (
          <Chip
            key={preference}
            label={preference}
            clickable
            color={selectedPreferences.includes(preference) ? 'primary' : 'default'}
            onClick={() => handleChipClick(preference)}
          />
        ))}
      </Box>
    </Box>
  );
}
