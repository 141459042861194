import Layout from "../layout/Layout";
// import GlobalLoginChecker from '../login/GlobalLoginChecker';

function Dashboard() {
  return (
    <>
      <Layout />
      {/* <GlobalLoginChecker /> */}
    </>
  );
}

export default Dashboard;
