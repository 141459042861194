import React from 'react';
import { ReactComponent as Logo } from '../../../images/valoo-logo.svg';
import AccountMenu from './menu/AccountMenu';
import styles from './Header.module.css';
import Assistant from './assistant/Assistant';
import { useIsMobile } from '../../../hooks/useIsMobile';

function Header() {

  // == Is Mobile - START ==
  const isMobile = useIsMobile();
  // == Is Mobile - END ==

  return(
    <>
      {isMobile ? (
        // Mobile version
        <>
          <div className='separatorEmptyMedium'></div>
          <header className={`${styles.container}`}>
            <div className={styles.logoContainer}>
              <Logo alt="Valoo Logo" className={styles.logo} />
            </div>
            <div>
              <Assistant />
            </div>
            <AccountMenu />
          </header>
          <div className='separatorEmptyMedium'></div>
        </>
      ) : (
        // Desktop version
        <>
          <header className={`${styles.container} maxWidth marginAuto`}>
            <div className={styles.logoContainer}>
              <Logo alt="Valoo Logo" className={styles.logo} />
            </div>
            <div>
              <Assistant />
            </div>
            <AccountMenu />
          </header>
        </>
      )}
    </>
  );
}

export default Header;