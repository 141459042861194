import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { CircularProgress } from "@mui/material";
import { apiInstance } from '../../services/api.service';

const PdfDialog = ({ companyId, fileName }) => {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleClickOpen = () => {
    setLoader(true);
    apiInstance.get(`/api/docs/document/link?companyId=${companyId}`)
      .then((data) => {
        data = data.data
        window.open(data?.docusend, "_blank");
        setLoader(false);
      })
      .catch((error) => console.error("Error fetching articles:", error));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filePath = `/api/stay-updated/files?companyId=${companyId}&fileName=${fileName}`

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        color="orangeText"
        sx={{ textTransform: "none" }}
      >
        {loader ? <CircularProgress color="orange" size={20} /> : "Read More"}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth="true">
        <DialogTitle>PDF Viewer</DialogTitle>
        <DialogContent>
          <DialogContentText>

            <iframe
              title="PDF Viewer"
              src={filePath}
              width="100%"
              height="700px"
            ></iframe>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PdfDialog;
