import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

const marks = [
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  }
];

export default function InvestmentPeriodSlider({ value, onChange }) {
  const valuetext = (value) => {
    return `${value} Years`;
  };

  return (
    <Box sx={{ width: 300 }}>
      <Typography id="investment-period-slider" gutterBottom>
        Investment Period
      </Typography>
      <Slider
        aria-labelledby="investment-period-slider"
        value={value}
        onChange={onChange}
        getAriaValueText={valuetext}
        step={1}
        min={2}
        max={10}
        marks={marks}
      />
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{`${value} Years`}</Typography>
    </Box>
  );
}