import React from "react";

export default function HourGlassIcon() {

  return (
    
    <svg width="90" height="90" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.2595 7.52991V13.4359C18.2595 25.5027 32.0001 32.1064 32.0001 32.1064C32.0001 32.1064 45.7407 25.5027 45.7407 13.4359V7.52991H18.2595Z" stroke="#FF7800" stroke-width="2.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M32.0001 32.1061C32.0001 32.1061 18.2595 38.7098 18.2595 50.7766V56.6826H45.7408V50.7766C45.7408 38.7098 32.0001 32.1061 32.0001 32.1061Z" stroke="#FF7800" stroke-width="2.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.2598 7.53027H14.561" stroke="#FF7800" stroke-width="2.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M45.7407 7.53027H49.4394" stroke="#FF7800" stroke-width="2.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.2598 56.6836H14.561" stroke="#FF7800" stroke-width="2.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M45.741 56.6826H49.4397" stroke="#FF7800" stroke-width="2.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.7686 20.1855H44.0731" stroke="#FF7800" stroke-width="2.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24.4518 50.0841L18.2598 56.6826H45.582L39.3899 50.0841C35.3439 45.7726 28.4979 45.7726 24.4518 50.0841Z" stroke="#FF7800" stroke-width="2.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  );

}