import React, { useState, useEffect, useContext } from "react";
import Slider from "@mui/material/Slider";
import { formatWithThousandsSeparators } from "../../../../../../utils/strings";
import { Button } from "@mui/material";
import { useAuth } from "../../../../../../hooks/useAuth";
import { CompanyContext } from "../../../../../state/CompanyContext";
import {
  sendLeadToZapier,
  buildLeadData,
  leadTypeEnum,
} from "../../../../../../utils/zapier";
import { apiInstance } from "../../../../../../services/api.service";

function OfferingBuy({ currentCompany, setActiveStep }) {
  const { range, price } = currentCompany?.steps?.stocks;
  const [numOfStocks, setNumOfStocks] = useState(0);
  const [pricePerStock, setPricePerStock] = useState(0);

  // Reset the sliders when the currentCompany value changes
  useEffect(() => {
    setNumOfStocks(range.defaultValue);
    setPricePerStock(price.defaultValue);
    // updateLeadContent(range.defaultValue, price.defaultValue)
  }, [currentCompany]);

  const handleNumOfStocksChange = (event, value) => {
    setNumOfStocks(value);
    // updateLeadContent(value, pricePerStock);
  };

  const handlePricePerStockChange = (event, value) => {
    setPricePerStock(value);
    // updateLeadContent(numOfStocks, value);
  };

  const total = (numOfStocks * pricePerStock).toFixed(2);

  //== api - START ==
  const handleBuyStocks = async () => {
    try {
      const response = await apiInstance.post("/api/stocks/buy/offering", {
        company_id: currentCompany.id,
        number_of_stocks: numOfStocks,
        price_per_stock: pricePerStock,
        steps: 1,
      });

      if (response.status <= 250) {
        setActiveStep(1);
        handleZapier();
        console.log("Stocks bought successfully");
      } else {
        console.error("Failed to buy stocks:", response.statusText);
      }
    } catch (error) {
      console.error("Error while buying stocks:", error.message);
    }
  };
  //== api - END ==

  //== Zapier - START ==
  const { user } = useAuth();
  const {
    state: { currentCompanyIdStocks },
  } = useContext(CompanyContext);

  const handleZapier = async () => {
    let dashboardType = "buy"; // buy / sell
    const leadContent = {
      userId: user.id,
      companyId: currentCompanyIdStocks,
      amountOfStocks: numOfStocks,
      pricePerShare: pricePerStock,
      dashboardType: dashboardType,
      step: 0,
      phone: user?.phone || "",
    };
    const leadData = buildLeadData(leadTypeEnum.StocksBuy, leadContent);
    await sendLeadToZapier(leadData);
  };
  //== Zapier - END ==

  return (
    <>
      <div>
        Browse the company details and the offer details, select an amount to
        purchase.
      </div>
      <div className="separatorEmptyLarge"></div>
      <form>
        <strong>Set the amount of stocks</strong>
        <div className="separatorEmptyMedium"></div>
        <Slider
          aria-label="Default"
          valueLabelDisplay="auto"
          step={1}
          value={numOfStocks}
          min={range.min}
          max={range.max}
          onChange={handleNumOfStocksChange}
        />
        <strong>Set the price per share</strong>
        <div className="separatorEmptyMedium"></div>
        <Slider
          aria-label="Default"
          valueLabelDisplay="auto"
          step={0.1}
          value={pricePerStock}
          min={price.min}
          max={price.max}
          onChange={handlePricePerStockChange}
        />
      </form>

      <div className="separatorEmptyLarge"></div>

      <table style={{ minWidth: "250px" }}>
        <tbody>
          <tr>
            <td>Num. Of Stocks</td>
            <td style={{ textAlign: "end" }}>
              <strong>{formatWithThousandsSeparators(numOfStocks)}</strong>
            </td>
          </tr>
          <tr>
            <td>Price Per Stock</td>
            <td style={{ textAlign: "end" }}>
              <strong>$ {formatWithThousandsSeparators(pricePerStock)}</strong>
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td style={{ textAlign: "end" }}>
              <strong className="orangeFont">
                $ {formatWithThousandsSeparators(total)}
              </strong>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="separatorEmptyMedium"></div>

      <div className="containerFlex flexEnd">
        <Button
          variant="contained"
          color="orange"
          sx={{ textTransform: "none" }}
          style={{ borderRadius: 20 }}
          onClick={handleBuyStocks}
        >
          Click Here To Proceed
        </Button>
      </div>
    </>
  );
}

export default OfferingBuy;
